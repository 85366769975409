import React from 'react'
import Grid from '@mui/material/Grid'

/** project components */
import Select from '../../../../components/SelectReact'
import DatePicker from '../../../../components/DatePicker'
import Button from '../../../../components/Button'
import Checkbox from '../../../../components/Checkbox'

const Filter = ({
  companies,
  selectedCompany,
  setSelectedCompany,
  contractors,
  selectedContractor,
  setSelectedContractor,
  assessments,
  selectedAssessment,
  setSelectedAssessment,
  expiredDate,
  setExpiredDate,
  searching,
  startSearching,
  validCheck,
  expiredCheck,
  handleValidCheck,
  handleExpiredCheck
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <Select
          id='company'
          label='Empresa'
          options={companies}
          selectedOption={selectedCompany}
          setValue={setSelectedCompany}
        />
      </Grid>
      <Grid item md={3}>
        <Select
          id='contractor'
          label='Contratista'
          options={contractors}
          selectedOption={selectedContractor}
          setValue={setSelectedContractor}
        />
      </Grid>
      <Grid item md={3}>
        <Select
          id='assessment'
          label='Evaluación'
          options={assessments}
          selectedOption={selectedAssessment}
          setValue={setSelectedAssessment}
        />
      </Grid>
      <Grid item md={2}>
        <DatePicker
          label='Fecha de vencimiento'
          date={expiredDate}
          setValue={setExpiredDate}
          dateFormat='MM/yyyy'
          showMonthYearPicker
        />
      </Grid>
      <Grid item md={1} style={{ paddingTop: '30px' }}>
        {searching ? (
          'Buscando...'
        ) : (
          <Button
            text='Buscar'
            variant='contained'
            disabled={!selectedCompany}
            onClick={() => startSearching(true)}
          />
        )}
      </Grid>
      <Grid item md={3}>
        <Checkbox
          id='valid'
          checked={validCheck}
          caption='Vigentes'
          onChange={() => handleValidCheck(!validCheck)}
          style={{ float: 'left' }}
        />
        <Checkbox
          id='expired'
          checked={expiredCheck}
          caption='Vencidos'
          onChange={() => handleExpiredCheck(!expiredCheck)}
          style={{ float: 'left' }}
        />
      </Grid>
    </Grid>
  )
}

export default Filter
