import { get } from 'lodash';
import { Storage } from '../../utils/storage';

export const clearAuth = (reload) => {
  Storage.clearAll();
  if (reload) {
    window.location.reload();
  }
};

export const setAuth = (data) => {
  Storage.setData('currentUser', data.user);
  Storage.setData('isLoggedIn', get(data, 'data.signedIn', true));
};

export const setHeaders = (headers) => {
  Storage.setData('authHeaders', headers);
};
