import React, { Suspense, lazy } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'

/** components */
import ErrorBoundary from './pages/error-boundary.jsx'

/** constants */
import {
  URL_LOGIN,
  URL_ROOT,
  URL_SETTINGS,
  URL_CLIENTS,
  URL_CONTRACTORS,
  URL_TRAININGS,
  URL_APPLICANT_ASSESSMENT,
  URL_APPLICANT_ASSESSMENT_CHILD,
  URL_APPLICANT_QR,
  URL_PRODUCT_QR,
  URL_EXECUTIVE,
  URL_APPLICANT,
  URL_EXPIRED,
  URL_TOOL,
  URL_DMS,
  URL_IDENTITY_LOGIN,
  URL_ATTENDANCE,
  URL_ICM,
  URL_PUBLIC_CARNET,
  URL_PUBLIC_CARNET_V1,
  URL_PUBLIC_PRIVACY_POLICY,
  URL_PUBLIC_ABOUT_US,
  URL_PUBLIC_APPS_LINK,
  URL_PUBLIC_REQUEST_APPOINTMENT,
  URL_PUBLIC_CONFIRM_APPOINTMENT,
  URL_PUBLIC_VIEW_APPOINTMENT,
  URL_UNAUTHORIZED
} from './utils/constants.js'

/** layouts */
import Main from './containers/main/index.jsx'

/** routers */
import RouterHome from './pages/Home/router.jsx'
import RouterTraining from './pages/Trainings/training.router.jsx'
import RouterClient from './pages/Clients/company.router.jsx'
import RouterExecutive from './pages/Executive/executive.router.jsx'
import RouterApplicant from './pages/Applicant/applicant.router.jsx'
import RooterInventory from './pages/Tool/inventory.router.jsx'
import RouterDMS from './pages/DMS/dms.router.jsx'
import RouterAttendance from './pages/Attendance/attendance.router.jsx'
import RouterICM from './pages/ICM/icm.router.jsx'
import RouterSetting from './pages/Settings/setting.router.jsx'
import RouterContractor from './modules/contractor/contractor.router.jsx'

/** pages */
import Expired from './pages/Expired'
import PrivateRoute from './components/private-route.jsx'
import Unauthorized from './pages/unauthorized.jsx'

// import routeContractor from './modules/contractor/contractor.router.js'

// public component pages
const ProductQr = lazy(() => import('./pages/ProductQr/index.jsx'))
const SignIn = lazy(() => import('./pages/SignIn/SignIn.jsx'))
const ApplicantAssessment = lazy(() => import('./pages/ApplicantAssessment/index.jsx'))
const ApplicantAssessmentChild = lazy(() =>
  import('./pages/ApplicantAssessment/applicant-assessment-child.jsx')
)

const ApplicantAssessmentCompleted = lazy(() =>
  import('./pages/ApplicantAssessment/applicant-assessment-completed.jsx')
)
const ApplicantRecord = lazy(() => import('./pages/ApplicantRecord/index.jsx'))
const NotFound = lazy(() => import('./pages/NotFound/index.jsx'))
const IdentityLogin = lazy(() => import('./pages/IdentityLogin/index.jsx'))
const PublicIcm = lazy(() => import('./pages/ICM/pages/public-icm.jsx'))
const PrivacyPolicy = lazy(() => import('./pages/privacy-policy/index.jsx'))
const AboutUs = lazy(() => import('./pages/about-us/index.jsx'))
const AppsLink = lazy(() => import('./pages/apps-link/index.jsx'))
const AppointmentRequest = lazy(() =>
  import('./pages/public-pages/appointment-request.jsx')
)
const AppointmentConfirm = lazy(() =>
  import('./pages/public-pages/appointment-confirm-page.jsx')
)
const ViewAppointment = lazy(() => import('./pages/public-pages/view-appointment.jsx'))
const CreateUserContractor = lazy(() =>
  import('./pages/public-pages/create-user-contractor.jsx')
)

const router = createBrowserRouter([
  {
    path: URL_ROOT,
    element: (
      <PrivateRoute allowedRoles={['admin', 'manager', 'user']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterHome
  },
  {
    path: URL_TRAININGS,
    element: (
      <PrivateRoute allowedRoles={['*']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterTraining
  },
  {
    path: URL_CLIENTS,
    element: (
      <PrivateRoute allowedRoles={['admin', 'manager', 'user']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterClient
  },
  {
    path: URL_CONTRACTORS,
    element: (
      <PrivateRoute allowedRoles={['admin', 'manager', 'user', 'contractor']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterContractor
  },
  {
    path: URL_EXECUTIVE,
    element: (
      <PrivateRoute allowedRoles={['admin', 'manager', 'user']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterExecutive
  },
  {
    path: URL_APPLICANT,
    element: (
      <PrivateRoute allowedRoles={['*']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterApplicant
  },
  {
    path: URL_EXPIRED,
    element: (
      <PrivateRoute allowedRoles={['*']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '',
        element: <Expired />
      }
    ]
  },
  {
    path: URL_TOOL,
    element: (
      <PrivateRoute
        allowedRoles={['admin']}
        moduleRoles={[{ module: 'inventoryRole', roles: ['manager'] }]}
      >
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RooterInventory
  },
  {
    path: URL_DMS,
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'manager', 'user']}
        moduleRoles={[{ module: 'dmsRole', roles: ['consultant', 'manager'] }]}
      >
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterDMS
  },
  {
    path: URL_ATTENDANCE,
    element: (
      <PrivateRoute
        allowedRoles={['admin', 'manager']}
        moduleRoles={[
          { module: 'attendanceRole', roles: ['administrator', 'manager', 'consultant'] }
        ]}
      >
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterAttendance
  },
  {
    path: URL_ICM,
    element: (
      <PrivateRoute allowedRoles={['admin', 'manager']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterICM
  },
  {
    path: URL_SETTINGS,
    element: (
      <PrivateRoute allowedRoles={['admin']}>
        <Main />
      </PrivateRoute>
    ),
    errorElement: <ErrorBoundary />,
    children: RouterSetting
  },
  {
    path: URL_UNAUTHORIZED,
    element: <Unauthorized />
  },
  {
    path: URL_LOGIN,
    element: <SignIn />
  },
  {
    path: URL_APPLICANT_ASSESSMENT,
    element: <ApplicantAssessment />
  },
  {
    path: URL_APPLICANT_ASSESSMENT_CHILD,
    element: <ApplicantAssessmentChild />
  },
  {
    path: '/applicantassessment/:token/completed',
    element: <ApplicantAssessmentCompleted />
  },
  {
    path: URL_APPLICANT_QR,
    element: <ApplicantRecord />
  },
  {
    path: URL_PRODUCT_QR,
    element: <ProductQr />
  },
  {
    path: URL_IDENTITY_LOGIN,
    element: <IdentityLogin />
  },
  {
    path: URL_PUBLIC_CARNET,
    element: <PublicIcm />
  },
  {
    path: URL_PUBLIC_CARNET_V1,
    element: <PublicIcm />
  },
  {
    path: URL_PUBLIC_PRIVACY_POLICY,
    element: <PrivacyPolicy />
  },
  {
    path: URL_PUBLIC_ABOUT_US,
    element: <AboutUs />
  },
  {
    path: URL_PUBLIC_APPS_LINK,
    element: <AppsLink />
  },
  {
    path: URL_PUBLIC_VIEW_APPOINTMENT,
    element: <ViewAppointment />
  },
  {
    path: URL_PUBLIC_CONFIRM_APPOINTMENT,
    element: <AppointmentConfirm />
  },
  {
    path: URL_PUBLIC_REQUEST_APPOINTMENT,
    element: <AppointmentRequest />
  },
  {
    path: '/public/new-user-contractor/:slug',
    element: <CreateUserContractor />
  },
  {
    path: '*',
    element: <NotFound />
  }
])

const DefaultRoutes = ({ history, isLoggedIn, loggedUser }) => (
  <ConnectedRouter history={history}>
    <Suspense fallback={<div>Cargando...</div>}>
      {/* <Switch> */}
      {/* Loads public routes */}
      {/* <Route path={URL_LOGIN} component={SignIn} />
        <Route path={URL_APPLICANT_ASSESSMENT} component={ApplicantAssessment} />
        <Route path={URL_APPLICANT_QR} component={ApplicantRecord} />
        <Route path={URL_PRODUCT_QR} component={ProductQr} />
        <Route path={URL_IDENTITY_LOGIN} component={IdentityLogin} />
        <Route path={URL_PUBLIC_CARNET} component={PublicIcm} />
        <Route path={URL_PUBLIC_CARNET_V1} component={PublicIcm} />
        <Route path={URL_PUBLIC_PRIVACY_POLICY} component={PrivacyPolicy} />
        <Route path={URL_PUBLIC_ABOUT_US} component={AboutUs} />
        <Route path={URL_PUBLIC_APPS_LINK} component={AppsLink} />
        <Route path={URL_PUBLIC_VIEW_APPOINTMENT} component={ViewAppointment} />
        <Route path={URL_PUBLIC_CONFIRM_APPOINTMENT} component={AppointmentConfirm} />
        <Route path={URL_PUBLIC_REQUEST_APPOINTMENT} component={AppointmentRequest} /> */}

      {/* Loads restricted routes */}
      {/* <RestrictedRoute
          exact
          path={URL_ROOT}
          router={routesHome}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          exact
          path={URL_HOME}
          router={routesHome}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_TRAININGS}
          router={routeTrainings}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user', 'security'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_CLIENTS}
          router={routeClients}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_CONTRACTORS}
          router={routeContractor}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_SETTINGS}
          router={routeSettings}
          isLoggedIn={isLoggedIn}
          role={['admin'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_EXECUTIVE}
          router={routeExecutive}
          isLoggedIn={isLoggedIn}
          role={['admin', 'manager', 'user'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_APPLICANT}
          router={routeApplicant}
          isLoggedIn={isLoggedIn}
          role
        />
        <RestrictedRoute
          path={URL_EXPIRED}
          router={routeExpired}
          isLoggedIn={isLoggedIn}
          role={['admin', 'user', 'security'].includes(loggedUser.role)}
        />
        <RestrictedRoute
          path={URL_TOOL}
          router={routeTool}
          isLoggedIn={isLoggedIn}
          role={
            ['admin'].includes(loggedUser.role) || loggedUser.inventoryRole === 'manager'
          }
        />
        <RestrictedRoute
          path={URL_DMS}
          router={routeDms}
          isLoggedIn={isLoggedIn}
          role={
            ['admin', 'manager'].includes(loggedUser.role) ||
            ['consultant', 'manager'].includes(loggedUser.dmsRole)
          }
        />
        <RestrictedRoute
          path={URL_ATTENDANCE}
          router={routeAttendance}
          isLoggedIn={isLoggedIn}
          role={
            ['admin', 'manager'].includes(loggedUser.role) ||
            loggedUser.attendanceRole !== null
          }
        />
        <RestrictedRoute
          path={URL_ICM}
          router={routeICM}
          isLoggedIn={isLoggedIn}
          role={
            ['admin', 'manager'].includes(loggedUser.role) ||
            loggedUser.attendanceRole !== null
          }
        /> */}

      {/* <Route path='*' component={NotFound} /> */}
      {/* </Switch> */}

      <RouterProvider router={router} />
    </Suspense>
  </ConnectedRouter>
)

// export default connect((state) => ({
//   isLoggedIn: checkAuthState(state),
//   loggedUser: checkAuthState(state) ? JSON.parse(localStorage.currentUser) : ''
// }))(DefaultRoutes)

export default DefaultRoutes
