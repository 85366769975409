import React from 'react'
import Typography from '@mui/material/Typography'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ elements }) => {
  const last = elements.pop()

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {elements.map((elem, index) => {
        return (
          <Link key={index} to={elem.url} style={{ textDecoration: 'none' }}>
            {elem.name}
          </Link>
        )
      })}
      <Typography color='textPrimary' style={{ fontWeight: 'bold' }}>
        {last.current}
      </Typography>
    </Breadcrumbs>
  )
}

export default Breadcrumb
