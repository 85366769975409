import React from 'react'
import { URL_TOOL, URL_TOOL_DETAIL } from '../../utils/constants'
import Tool from './index'
import ToolDetail from './page/ToolDetail'

const routeTool = [
  {
    path: URL_TOOL,
    element: <Tool />
  },
  {
    path: URL_TOOL_DETAIL,
    element: <ToolDetail />
  }
]

export default routeTool
