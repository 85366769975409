import React, { useState, useEffect } from 'react';
import '../../styles/pages/Expired/index.css';
import Moment from 'moment';

/** page components */
import Filter from './components/Filter';
import ResultData from './components/ResultData/ResultData';

/** services */
import { getClients } from '../../services/clientService';
import { getAllContractor } from '../../services/contractorService';
import { getAssessmentsConfig } from '../../services/assessmentServ';
import { getExpiredResults } from '../../services/resultsService';

const Expired = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const [companiesData, setCompaniesData] = useState([]);
  const [contractorsData, setContractorsData] = useState([]);
  const [assessmentsData, setAssessmentsData] = useState([]);

  /** filter params */
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedContractor, setSelectedContractor] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [expiredDate, setExpiredDate] = useState(new Date());
  const [valid, setValid] = useState(true);
  const [expired, setExpired] = useState(false);
  const [searching, setSearching] = useState(false);

  /** start search */
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const loadCompanies = async () => {
      const res = await getClients();

      if (res && res.status === 200) {
        setCompaniesData(
          res.data.map((client) => {
            return { value: client.id, label: client.name };
          })
        );
      }
    };

    const loadContractors = async () => {
      const res = await getAllContractor();

      if (res && res.status === 200) {
        setContractorsData(
          res.data.data.map((contractor) => {
            return { value: contractor.id, label: contractor.name };
          })
        );
      }
    };

    const loadAssessments = async () => {
      const res = await getAssessmentsConfig();

      if (res && res.status === 200) {
        setAssessmentsData(
          res.data.map((assessment) => {
            return { value: assessment.id, label: assessment.name };
          })
        );
      }
    };

    // if (loading) {
    loadCompanies();
    loadContractors();
    loadAssessments();
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loadSearching = async () => {
      const res = await getExpiredResults({
        selectedCompany,
        selectedContractor,
        selectedAssessment,
        expiredDate: Moment(Moment(expiredDate).endOf('month')).format('YYYY-MM-DD'),
        valid,
        expired
      });

      if (res && res.status === 200) {
        setFilteredData(res.data.data);
      } else {
        setFilteredData([]);
      }

      setSearching(false);
    };

    if (searching) {
      loadSearching();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searching]);

  return (
    <div>
      <h3>Exámenes próximos a vencer</h3>
      <hr />
      <p>
        La plataforma, automáticamente actualiza los exámenes que alcanzan la fecha de
        vencimiento, mediante filtros el usuario puede encontrar o identificar los
        examenes próximos a vencer en un rango de fecha determinado
      </p>
      <div className="filter-div">
        <Filter
          companies={companiesData}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          contractors={contractorsData}
          selectedContractor={selectedContractor}
          setSelectedContractor={setSelectedContractor}
          assessments={assessmentsData}
          selectedAssessment={selectedAssessment}
          setSelectedAssessment={setSelectedAssessment}
          expiredDate={expiredDate}
          setExpiredDate={setExpiredDate}
          searching={searching}
          startSearching={setSearching}
          validCheck={valid}
          expiredCheck={expired}
          handleValidCheck={setValid}
          handleExpiredCheck={setExpired}
        />
      </div>
      <br />
      <div>
        <ResultData data={filteredData} />
      </div>
    </div>
  );
};

export default Expired;
