import React, { useState } from 'react';

/** app components */
import Button from '../../../../../components/Button';

/** local components */
import ObservationData from './components/ObservationData';
import ObservationDetail from './components/ObservationDetail';

/**app service */
import { addObservation } from '../../../../../services/inventoryService';

const Observation = ({ productId }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [description, setDescription] = useState('');
  const [loadObservations, setLoadObservations] = useState(true);

  const handleAddObservation = async () => {
    const observation = {
      productId: productId,
      description: description
    };

    const resp = await addObservation(observation);

    if (resp && resp.status === 201) {
      setLoadObservations(true);
      setShowDialog(false);
    } else {
      console.log('No se pudo agregar la observación');
    }
  };

  return (
    <div>
      <div>
        <Button
          text="Nueva Observación"
          variant="outlined"
          onClick={() => setShowDialog(true)}
        />
      </div>
      <br />
      <div>
        <ObservationData
          productId={productId}
          loadData={loadObservations}
          setLoadData={setLoadObservations}
        />
      </div>
      {showDialog && (
        <ObservationDetail
          handleClose={() => setShowDialog(false)}
          handleAdd={handleAddObservation}
          description={description}
          handleDescription={(value) => setDescription(value)}
        />
      )}
    </div>
  );
};

export default Observation;
