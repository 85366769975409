import { getApi } from './api'
import * as Sentry from '@sentry/react'

/** update applicant information */
export async function updateApplicant(applicant) {
  try {
    const response = await getApi().put(`/applicant/${applicant.id}`, {
      ...applicant
    })

    return response
  } catch (e) {
    console.log(e.status, e.message)
    return e.message
  }
}

/** update assessment */
export async function updateAssessment(assessmentResultId) {
  try {
    const response = await getApi().put(`/results/assessments/${assessmentResultId}`, {
      data: {}
    })

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/** post results assessment */
export async function postResults(results) {
  try {
    const response = await getApi().post(`/results/${results.assessmentId}`, {
      results
    })

    return response
  } catch (error) {
    console.log(error.status)
    return error.message
  }
}

/** upload applicant image */
export async function uploadProfilePicture(applicantId, file) {
  try {
    if (!applicantId || !file) {
      throw new Error('Invalid')
    }

    return await getApi().post(`/applicant/image/${applicantId}`, file, {})
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}

/** upload applicant image */
export async function updateResult(assessmentResult) {
  try {
    const response = await getApi().put(`/assessmentResult/${assessmentResult.id}`, {
      ...assessmentResult
    })

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/** get assessment result by id */
export async function getResultDetail(assessmentResultId) {
  try {
    const response = await getApi().get(`/assessmentResult/${assessmentResultId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/** get expired assessment result */
export async function getExpiredResults(params) {
  try {
    const response = await getApi().get(
      `/assessmentResult/expired?expireDate=${params.expiredDate}&valid=${
        params.valid
      }&expired=${params.expired}&clientId=${
        params.selectedCompany && params.selectedCompany.value
      }&assessmentId=${
        params.selectedAssessment && params.selectedAssessment.value
      }&contractorId=${params.selectedContractor && params.selectedContractor.value}`,
      {}
    )

    return response
  } catch (e) {
    return e.message
  }
}

/** post sent certificate emails manual */
export async function sendCertifiedCopy(params) {
  try {
    const response = await getApi().post(`/assessmentResult/emailCertified`, {
      assessmentResultId: params.id,
      email: params.email
    })

    if (response.status === 200) {
      return true
    }
    return false
  } catch (e) {
    console.log(
      '🚀 ~ file: resultsService.js ~ line 117 ~ sendCertifiedCopy ~ e.message',
      e.message
    )
    return false
  }
}

export async function getChildAssessmentByToken(token) {
  try {
    const response = await getApi().get(`/results/${token}/assessment`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function getQuestionsAssessmentByToken(token) {
  try {
    /** add assessments */
    const response = await getApi().get(`/results/${token}/questions`, {})

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function submitAnswers(token, answers) {
  try {
    const response = await getApi().post(`/results/${token}/answersToQuestions`, {
      answers: {
        ...answers
      }
    })

    return response
  } catch (error) {
    console.log(error.status)
    return error.message
  }
}
