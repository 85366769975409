import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';

const Loading = () => {
  return (
    <div className="sweet-loading">
      <SyncLoader size={15} margin={2} color="#4A90E2" loading={true} />
    </div>
  );
};

export default Loading;
