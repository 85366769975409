import React from 'react'
import Grid from '@mui/material/Grid'
// import InputVektor from '../../.../../../../../components/InputVektor';
import Input from '../../../../../components/Input'
import Select from '../../../../../components/Select'
import StatusGroup from './components/StatusGroup'

import './css/mainCard.css'

const GeneralInformation = ({
  product,
  setProduct,
  companies,
  contractors,
  users,
  valClient,
  updateValClient,
  valContractor,
  updateContractor,
  valResponsible,
  updateResponsible
}) => {
  const updateStatus = (value) => {
    setProduct({ ...product, status: value })
  }

  return (
    <Grid container spacing={0}>
      <Grid item md={6} className='first-column-card'>
        {product.clientId !== undefined && (
          <Select
            id='select-client-tool'
            caption='Cliente *'
            defaultValue=''
            value={product.clientId}
            setValue={(e) => {
              updateValClient({ error: false, helperText: '' })
              setProduct({ ...product, clientId: e ? e.id : null })
            }}
            options={companies}
            error={valClient.error}
            helperText={valClient.helperText}
          />
        )}
      </Grid>
      <Grid item md={6}>
        <StatusGroup
          id='status-tool'
          value={product.status}
          selectedStatus={updateStatus}
        />
      </Grid>
      <Grid item md={6} className='first-column-card'>
        <Select
          id='select-contractor-tool'
          caption='Contratista o División Industrial *'
          value={product.contractorId !== undefined ? product.contractorId : null}
          setValue={(e) => {
            updateContractor({ error: false, helperText: '' })
            setProduct({ ...product, contractorId: e ? e.id : null })
          }}
          options={contractors}
          error={valContractor.error}
          helperText={valContractor.helperText}
        />
      </Grid>
      <Grid item md={6}>
        <Input
          id='tool-description'
          caption='Descripción'
          value={product.description}
          onChange={(e) => setProduct({ ...product, description: e.target.value })}
        />
      </Grid>
      <Grid item md={6} className='first-column-card'>
        <Select
          id='select-responsible-tool'
          caption='Responsable *'
          value={product.responsibleUserId}
          setValue={(e) => {
            updateResponsible({ error: false, helperText: '' })
            setProduct({ ...product, responsibleUserId: e ? e.id : null })
          }}
          options={users}
          error={valResponsible.error}
          helperText={valResponsible.helperText}
        />
      </Grid>
      <Grid item md={6}>
        <Input
          id='tool-location'
          caption='Localización'
          value={product.location}
          onChange={(e) => setProduct({ ...product, location: e.target.value })}
        />
      </Grid>
    </Grid>
  )
}

export default GeneralInformation
