import React, { lazy } from 'react'
import {
  URL_SETTINGS,
  URL_SETTINGS_USERS,
  URL_SETTINGS_USERS_DETAIL,
  URL_SETTINGS_ASSESSMENTS,
  URL_SETTINGS_ASSESSMENTS_DETAIL,
  URL_SETTINGS_COMPANY,
  URL_SETTINGS_LOGS,
  URL_SETTINGS_QUESTIONS,
  URL_SETTINGS_QUESTION_DETAIL,
  URL_SETTINGS_SECTIONS,
  URL_SETTINGS_MATRIX,
  URL_SETTINGS_MATRIX_COLUMN,
  URL_SETTINGS_MATRIX_ROW,
  URL_SETTINGS_MATRIX_CATEGORY,
  URL_SETTINGS_MATRIX_REQUIREMENT
} from '../../utils/constants'

const SettingsPanel = lazy(() => import('./index'))
const Users = lazy(() => import('../Users/Users'))
const UserCard = lazy(() => import('../Users/UserCard'))
const AssessmentsSettings = lazy(() =>
  import('./components/assessment/AssessmentsSettings')
)
const AssessmentDetail = lazy(() => import('./components/assessment/AssessmentDetail'))
const Company = lazy(() => import('./Company'))
const Logs = lazy(() => import('./Logs'))
const Questions = lazy(() => import('./Questions'))
const QuestionDetail = lazy(() => import('./QuestionDetail'))
const Sections = lazy(() => import('./Sections'))

/** matrix setting */
const Matrix = lazy(() => import('./matrix/matrix'))
const Column = lazy(() => import('./matrix/column'))
const Row = lazy(() => import('./matrix/row'))
const Category = lazy(() => import('./matrix/category'))
const Requirement = lazy(() => import('./matrix/requirement'))

const routeSettings = [
  {
    path: URL_SETTINGS,
    element: <SettingsPanel />
  },
  {
    path: URL_SETTINGS_USERS,
    element: <Users />
  },
  {
    path: URL_SETTINGS_USERS_DETAIL,
    element: <UserCard />
  },
  {
    path: URL_SETTINGS_ASSESSMENTS,
    element: <AssessmentsSettings />
  },
  {
    path: URL_SETTINGS_ASSESSMENTS_DETAIL,
    element: <AssessmentDetail />
  },
  {
    path: URL_SETTINGS_COMPANY,
    element: <Company />
  },
  {
    path: URL_SETTINGS_LOGS,
    element: <Logs />
  },
  {
    path: URL_SETTINGS_QUESTIONS,
    element: <Questions />
  },
  {
    path: URL_SETTINGS_QUESTION_DETAIL,
    element: <QuestionDetail />
  },
  {
    path: URL_SETTINGS_SECTIONS,
    element: <Sections />
  },
  {
    path: URL_SETTINGS_MATRIX,
    element: <Matrix />
  },
  {
    path: URL_SETTINGS_MATRIX_COLUMN,
    element: <Column />
  },
  {
    path: URL_SETTINGS_MATRIX_ROW,
    element: <Row />
  },
  {
    path: URL_SETTINGS_MATRIX_CATEGORY,
    element: <Category />
  },
  {
    path: URL_SETTINGS_MATRIX_REQUIREMENT,
    element: <Requirement />
  }
]

export default routeSettings
