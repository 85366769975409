import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography'

import DefaultImage from '../../../../assets/img/no-image.png'

import ProductStatus from '../../components/ProductStatus/ProductStatus'

const useStyles = makeStyles({
  root: {
    width: 350,
    height: 140,
    marginRight: '15px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  }
})

const ProductCard = ({ id, code, name, status, description, avatarUrl }) => {
  const classes = useStyles()
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <Link
        to={`/tool/${id}`}
        style={{ textDecoration: 'none', textTransform: 'none !important' }}
      >
        <CardActionArea>
          <CardContent>
            <div style={{ float: 'left' }}>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                {code}
              </Typography>
              <Typography variant='h5' component='h2'>
                {name}
              </Typography>
              <Typography
                className={classes.pos}
                color='textSecondary'
                style={{ marginTop: '10px' }}
              >
                <ProductStatus {...{ value: status }} />
              </Typography>
              <Typography variant='body2' component='p'>
                {description}
              </Typography>
            </div>
            <div style={{ float: 'right' }}>
              <img
                key='image-product'
                src={avatarUrl || DefaultImage}
                className='image'
                alt='imagen del producto'
                width='80px'
              />
            </div>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  )
}

export default ProductCard
