import { format, parseISO } from 'date-fns'

export function parseDate(value) {
  return new Date(value).toLocaleString('es-CR', {
    hour12: true
  })
}

export function parseShortDate(value) {
  if (!value) {
    return '--'
  }

  return new Date(value).toLocaleDateString('es-CR')
}

export function getMonthById(monthId) {
  const month = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  return month[monthId - 1]
}

export function parseExpiredExcel(data) {
  const result = []
  for (const value of data) {
    result.push({
      nombre: value.Applicant.name,
      apellido: value.Applicant.lastName,
      identificacion: value.Applicant.identityCard,
      empresa: value.ClientAssessment.Client.name,
      contratista: value.Contractor.name,
      evaluacion: value.ClientAssessment.assessment.name,
      fechaInvitacion: value.invitationDate,
      fechaCompletado: value.completedDate,
      fechaValidez: value.validityDate,
      estado: value.validityStatus,
      nota: value.score
    })
  }

  return result
}

export function parseContractorsExcel(data) {
  const result = []
  for (const value of data) {
    result.push({
      Nombre: value.name,
      Email: value.email,
      Contacto: value.contactPerson,
      Email_Contacto: value.emailContactPerson,
      Telefono: value.phone
    })
  }

  return result
}

export function parseAssessExcel(data) {
  const result = []
  for (const value of data) {
    result.push({
      'No. Evaluación': value.id,
      Nombre: value.Applicant ? value.Applicant.name : value.applicantName,
      Apellidos: value.Applicant ? value.Applicant.lastName : value.applicantLastName,
      Email: value.Applicant ? value.Applicant.email : value.applicantEmail,
      Cédula: value.Applicant ? value.Applicant.identityCard : value.applicantIdentity,
      Contratista: value.Contractor ? value.Contractor.name : value.contractorName,
      ...(value.ClientAssessment && {
        Evaluación: value.ClientAssessment.assessment.name
      }),
      'Fecha de Invitación': value.invitationDate
        ? format(parseISO(value.invitationDate), 'dd/MM/yyyy hh:mm a')
        : '-',
      Estado: value.status,
      Puntuación: value.score,
      'Completado el': value.completedDate
        ? format(parseISO(value.completedDate), 'dd/MM/yyyy')
        : '-',
      'Hora de finalización': value.completedDate
        ? format(parseISO(value.completedDate), 'hh:mm a')
        : '-',
      'Es válido': value.validityStatus,
      'Válido al': value.validityDate
        ? format(parseISO(value.validityDate), 'dd/MM/yyyy hh:mm a')
        : '-',
      'Meses de validéz': value.validityMonth,
      Intentos: value.attempt,
      Reinicios: value.reboot,
      ...(value.description && {
        Descripción: value.description
      }),
      ...(value.link && {
        Enlace: value.link
      }),
      ...(value.observations && {
        Observaciones: value.observations
      }),
      ...(value.requirement && {
        Requerimientos: value.requirement
      }),
      ...(value.createdAt && {
        'Creado el': value.createdAt
          ? format(parseISO(value.createdAt), 'dd/MM/yyyy hh:mm a')
          : '-'
      }),
      ...(value.updatedAt && {
        'Última actualización': value.updatedAt
          ? format(parseISO(value.updatedAt), 'dd/MM/yyyy hh:mm a')
          : '-'
      })
    })
  }

  return result
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export function capitalizeFirstLetters(inputString) {
  if (inputString === null || inputString === undefined) {
    return ''
  }

  const words = inputString.split(' ')

  const capitalizedWords = words.map((word) => {
    if (word.length === 0) {
      return word // Mantener palabras vacías como están
    }

    const firstChar = word.charAt(0).toUpperCase()
    const restOfString = word.slice(1).toLowerCase()

    return firstChar + restOfString
  })

  return capitalizedWords.join(' ')
}

export function getCookie(name) {
  const cookie = document.cookie
    .split('; ')
    .find((cookie) => cookie.startsWith(name + '='))

  return cookie ? cookie.split('=')[1] : null
}

export function translateICMStatus(value) {
  switch (value) {
    case 'draft':
      return 'Borrador'
    case 'valid':
      return 'Vigente'
    case 'expired':
      return 'Expirado'
    case 'archived':
      return 'Archivado'
    default:
      return 'Revocado'
  }
}

export function translateRequirementStatus(value) {
  switch (value) {
    case 'pending':
      return 'Pendiente'
    case 'in progress':
      return 'En progreso'
    case 'completed':
      return 'Completado'
    case 'approved':
      return 'Aprobado'
    case 'rejected':
      return 'Rechazado'
    case 'overdue':
      return 'Atrasado'
    case 'not applicable':
      return 'No aplicable'
    case 'deferred':
      return 'Diferido'
    case 'failed':
      return 'Fallido'
    case 'reviewed':
      return 'Revisado'
    default:
      return value
  }
}

export function translateExpiredStatus(value) {
  switch (value) {
    case 'valid':
      return 'Vigente'
    case 'expired':
      return 'Vencido'
    case 'renewed':
      return 'Renovado'
    case 'archived':
      return 'Archivado'
    default:
      return value
  }
}

export function translateComplianceDocumentStatus(value) {
  switch (value) {
    case 'draft':
      return 'Borrador'
    case 'submitted':
      return 'Enviado'
    case 'under review':
      return 'En revisión'
    case 'approved':
      return 'Aprobado'
    case 'rejected':
      return 'Rechazado'
    case 'pending updates':
      return 'Actualizaciones pendientes'
    case 'completed':
      return 'Completado'
    case 'archived':
      return 'Archivado'
    case 'expired':
      return 'Vencido'
    case 'in progress':
      return 'En progreso'
    case 'on hold':
      return 'En espera'
    case 'cancelled':
      return 'Cancelado'
    default:
      return value
  }
}

export function getValidAssessment(assessmentList, minYear) {
  const valid = assessmentList
    .filter((assessment) => !assessment.isDiagnostic)
    .filter((assessment) => new Date(assessment.completedDate).getFullYear() >= minYear)

  const approvedValid = assessmentList
    .filter((assessment) => !assessment.isDiagnostic)
    .filter((assessment) => new Date(assessment.completedDate).getFullYear() >= minYear)
    .filter((x) => x.status === 'approved' && x.validityStatus === 'valid')

  const approvedExpired = assessmentList
    .filter((assessment) => !assessment.isDiagnostic)
    .filter((assessment) => new Date(assessment.completedDate).getFullYear() >= minYear)
    .filter((x) => x.status === 'approved' && x.validityStatus === 'expired')

  const suspended = assessmentList
    .filter((assessment) => !assessment.isDiagnostic)
    .filter((assessment) => new Date(assessment.completedDate).getFullYear() >= minYear)
    .filter((x) => x.status === 'suspended')

  const archived = assessmentList
    .filter((assessment) => !assessment.isDiagnostic)
    .filter((assessment) => new Date(assessment.completedDate).getFullYear() < minYear)

  const diagnostics = assessmentList.filter((assessment) => assessment.isDiagnostic)

  return { valid, archived, diagnostics, approvedValid, approvedExpired, suspended }
}
