import React from 'react'
import PageTitle from '@components/page-title'
import { useNavigate } from 'react-router-dom'
import { URL_LOGIN } from '@utils/constants'

export default function Unauthorized() {
  const navigate = useNavigate()
  return (
    <div className='main-container'>
      <PageTitle text='Acceso no autorizado' />
      <p>
        La ruta que intenta acceder no está autorizada, puede contactar al administrador
        del sistema, para obtener los accesos necesarios
      </p>

      <button onClick={() => navigate(URL_LOGIN)}>Regresar a Inicio</button>
    </div>
  )
}
