import React, { lazy } from 'react'
import { URL_ICM, URL_ICM_DETAIL } from '../../utils/constants'

const Icm = lazy(() => import('./index'))
const IdentityCard = lazy(() => import('./pages/icm'))

const routeICM = [
  {
    path: URL_ICM,
    element: <Icm />
  },
  {
    path: URL_ICM_DETAIL,
    element: <IdentityCard />
  }
]
export default routeICM
