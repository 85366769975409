import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  shadows: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
    25, 26
  ],
  palette: {
    primary: {
      main: '#1976d2'
    }
  },
  typography: {
    button: {
      fontSize: '14px',
      letterSpacing: '0.25px',
      textTransform: 'unset'
    }
  }
})

export default theme
