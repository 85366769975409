import React from 'react'

/** Material ui component */
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import Button from '../ButtonVektor'

export default function VektorDialog({
  open,
  title,
  content,
  handleClose,
  handleConfirm
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button text='Cancelar' onClick={handleClose} variant='outlined' />
        <Button text='Aceptar' onClick={handleConfirm} variant='contained' />
      </DialogActions>
    </Dialog>
  )
}
