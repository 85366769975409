import axios from 'axios'
import { get } from 'lodash'
import { clearAuth } from '../pages/SignIn/helper'
import { Storage } from '../utils/storage'
import { URL_LOGIN } from '../utils/constants'

const logout = () => {
  clearAuth()
  window.location.href = URL_LOGIN
}

/**
 * Returns object with authentication header data.
 *
 * @param {boolean} noContentType Flag to add Content-Type=undefined header
 */
export const getApiHeaders = (noContentType) => {
  let headers = Storage.getData('authHeaders')
  // console.log('headers', headers);
  // delete headers['content-length'];

  if (noContentType) {
    headers['Content-Type'] = undefined
  }

  return headers
}

/**
 * Set axios configuration.
 * @param {Boolean} useStringify
 */
export const setAxiosConfig = (useStringify) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL

  if (getApiHeaders()) {
    axios.defaults.headers.common = getApiHeaders()
  }

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'

  if (useStringify) {
    // Transform data payload with stringify.
    axios.defaults.transformRequest = (obj) => JSON.stringify(obj)
  }
}

export const getApi = (useStringify, customFn) => {
  setAxiosConfig(useStringify, customFn)
  const axiosInstance = axios.create()

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (get(error, 'response.status') === 401) {
        logout()
      }

      return Promise.reject({
        data: get(error, 'response.data'),
        status: get(error, 'response.status')
      })
    }
  )

  return axiosInstance
}
