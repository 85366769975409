import React, { lazy } from 'react'

const Home = lazy(() => import('./Home'))

const routesHome = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/home',
    element: <Home />
  }
]

export default routesHome
