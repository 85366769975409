import * as React from 'react'
import { makeStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import DefaultImage from '../../../../assets/img/no-image.png'

const useStyles = makeStyles({
  root: {
    width: 350,
    height: 140,
    marginRight: '15px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: '4px'
  }
})

const ClientCard = ({ id, name, products, selectedClient }) => {
  const classes = useStyles()
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={(_) => selectedClient(id)}>
        <CardContent>
          <div style={{ float: 'left', width: '235px' }}>
            <Typography className={classes.title} color='textSecondary' gutterBottom>
              {`Código: ${id}`}
            </Typography>
            <Typography variant='h5' component='h2'>
              {name}
            </Typography>
            <Typography className={classes.pos} color='textSecondary'>
              {'Cantidad de productos: '}
              <span style={{ color: 'black' }}>{`${products.length}`}</span>
            </Typography>
            <Typography variant='body2' component='p'>
              {/* {description} */}
            </Typography>
          </div>
          <div style={{ float: 'right' }}>
            <img
              key='image-product'
              // src={avatarUrl ? avatarUrl : DefaultImage}
              src={DefaultImage}
              className='image'
              alt='imagen del producto'
              width='80px'
            />
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ClientCard
