import React from 'react';
import { Link } from 'react-router-dom';

/** import local components */
import Button from '../../components/Button';
import { URL_TOOL } from '../../utils/constants';
import ToolData from './components/ToolData';

const Tool = () => {
  return (
    <div>
      <h3>Herramientas y Maquinaria</h3>
      <div style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Link to={`${URL_TOOL}/new`}>
          <Button text="Nueva herramienta" variant="contained" />
        </Link>
      </div>
      <div>
        <ToolData />
      </div>
    </div>
  );
};

export default Tool;
