import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import './gallery.css'

import IconButton from '@mui/material/IconButton'
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'
import Gallery from 'react-photo-gallery'
import Carousel, { Modal, ModalGateway } from 'react-images'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

// import { photos } from './photos';
import { uploadProductGalleryImg } from '../../../../../services/inventoryService'
import {
  uploadSheetImage,
  deleteAWSPhoto
} from '../../../../../services/attendanceService'

const SelectedImage = ({
  photo,
  onClick,
  index,
  margin,
  direction,
  top,
  left,
  handleConfirm
}) => {
  const cont = {
    backgroundColor: '#eee',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative'
  }

  if (direction === 'column') {
    cont.position = 'absolute'
    cont.left = left
    cont.top = top
  }

  return (
    <div
      className='gallery-image'
      style={{ height: photo.height, width: photo.width, ...cont }}
    >
      <img
        style={{ margin, height: photo.height, width: photo.width, ...cont }}
        alt={photo.title}
        {...photo}
      />
      <div className='button-image'>
        <button onClick={() => onClick(index)}>Ver</button>
        <button style={{ marginLeft: '10px' }} onClick={() => handleConfirm(photo)}>
          Eliminar
        </button>
      </div>
      {/* <style>{`.not-selected:hover{cursor:pointer}`}</style> */}
    </div>
  )
}

export default function ProductGallery({
  productId,
  photos,
  product,
  setProduct,
  isSheet
}) {
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)

  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const [showConfirm, setShowConfirm] = React.useState(false)
  const [deleteURL, setDeleteURL] = React.useState(null)
  // const [selectAll, setSelectAll] = useState(false)
  const selectAll = false

  useEffect(() => {
    const uploadImage = async () => {
      // Create an object of formData
      const formData = new FormData()

      // Update the formData object
      formData.append('File', selectedFile)

      const res =
        isSheet === true
          ? await uploadSheetImage(productId, formData)
          : await uploadProductGalleryImg(productId, formData)

      if (res && res.status === 200) {
        setProduct({ ...product, gallery: res.data ? res.data.data : [] })
        setIsFilePicked(false)
      }
    }

    if (isFilePicked) {
      uploadImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilePicked])

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setIsFilePicked(true)
  }

  const openLightbox = useCallback((index) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
    // eslint-disable-next-line
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  const handleConfirm = (photo) => {
    setDeleteURL(photo.src)
    setShowConfirm(true)
  }

  const handleCloseConfirm = () => {
    setDeleteURL(null)
    setShowConfirm(false)
  }

  const handleDeleteAWSPhoto = async () => {
    try {
      if (isSheet === true) {
        const res = await deleteAWSPhoto(deleteURL, productId)

        if (res && res.status === 200) {
          setProduct({ ...product, gallery: res.data ? res.data.data : [] })
          setIsFilePicked(false)
        }

        handleCloseConfirm()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const imageRenderer = React.useCallback(
    ({ index, left, top, key, photo }) => (
      <SelectedImage
        selected={!!selectAll}
        key={key}
        margin='2px'
        index={index}
        photo={photo}
        left={left}
        top={top}
        onClick={openLightbox}
        handleConfirm={handleConfirm}
      />
    ),
    // eslint-disable-next-line
    [selectAll]
  )

  return (
    <>
      <div>
        <input
          accept='image/png, image/jpeg'
          id='icon-button-file'
          type='file'
          style={{ display: 'none' }}
          onChange={onFileChange}
        />
        <label htmlFor='icon-button-file'>
          <IconButton
            color='primary'
            aria-label='upload document'
            size='medium'
            component='span'
          >
            <PhotoLibraryIcon style={{ fontSize: 35 }} />
          </IconButton>
        </label>
      </div>
      <div>
        <Gallery photos={photos} renderImage={imageRenderer} />
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={photos.map((x) => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {showConfirm && (
          <ConfirmDialog
            title='Eliminar foto'
            description='Está seguro que desea eliminar la foto de la galería.'
            handleClose={handleCloseConfirm}
            handleConfirm={handleDeleteAWSPhoto}
          />
        )}
      </div>
    </>
  )
}

ProductGallery.defaultProps = {
  isSheet: false
}

ProductGallery.propTypes = {
  isSheet: PropTypes.bool
}
