import React, { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useRouteError, isRouteErrorResponse, Outlet } from 'react-router-dom'

function ErrorBoundary() {
  const error = useRouteError()

  // const renderErrorMessage = () => {
  //   if (isRouteErrorResponse(error)) {
  //     return (
  //       <>
  //         <h2>Lo sentimos, ocurrió un error: {error.statusText || error.message}</h2>
  //         <p>Error status: {error.status}</p>
  //       </>
  //     );
  //   }
  //   return <h2>Ocurrió un error inesperado</h2>;
  // };

  const renderErrorMessage = () => {
    if (isRouteErrorResponse(error)) {
      switch (error.status) {
        case 404:
          return "This page doesn't exist!"
        case 401:
          return "You aren't authorized to see this"
        case 503:
          return 'Looks like our API is down'
        case 418:
          return '🫖'
        default:
          return `Error ${error.status}: ${error.statusText}`
      }
    } else {
      return `Error: ${error.message || 'Something went wrong!'}`
    }
  }

  // const renderErrorDetails = () => {
  //   return error?.stack ? <details>{error.stack}</details> : null;
  // };

  const renderErrorDetails = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <div>
          <p>
            <strong>Status:</strong> {error.status}
          </p>
          <p>
            <strong>Status Text:</strong> {error.statusText}
          </p>
          {error.data && (
            <p>
              <strong>Data:</strong> {JSON.stringify(error.data)}
            </p>
          )}
        </div>
      )
    } else {
      return (
        <div>
          <p>
            <strong>Name:</strong> {error.name}
          </p>
          <p>
            <strong>Message:</strong> {error.message}
          </p>
          {error.stack && (
            <pre>
              <strong>Stack Trace:</strong> {error.stack}
            </pre>
          )}
        </div>
      )
    }
  }

  // Captura el error con Sentry al cargar el componente
  useEffect(() => {
    if (error) {
      Sentry.captureException(error)
    }
  }, [error])

  // return (
  //   <div className="main-container">
  //     <h1>Se ha producido un error en la página</h1>
  //     <p>Intente nuevamente o contacte al administrador</p>
  //     <p>Detalle del error: {renderErrorMessage()}</p>
  //     {renderErrorDetails()}
  //     <button onClick={() => window.history.back()}>Atrás</button>
  //   </div>
  // );

  return (
    <div className='main-container'>
      <h1>Se ha producido un error en la página</h1>
      <p>Intente nuevamente o contacte al administrador</p>
      <p>Detalle del error: {renderErrorMessage()}</p>
      {renderErrorDetails()}
      <Outlet />
      <button onClick={() => window.history.back()}>Atrás</button>
    </div>
  )
}

export default ErrorBoundary
