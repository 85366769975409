import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'

/** App component */
import Button from '../../../../../../components/Button'
import Input from '../../../../../../components/Input'

const ObservationDetail = ({
  description,
  handleClose,
  handleAdd,
  handleDescription
  // handleConfirm
}) => {
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Observación</DialogTitle>
      <DialogContent>
        <Input
          id='observation-product'
          label='Descripción'
          multiline
          rows={2}
          rowsMax={4}
          style={{ width: 400 }}
          //   error={validateName.error}
          //   helperText={validateName.helperText}
          value={description}
          onChange={(e) => {
            handleDescription(e.target.value)
          }}
          margin='normal'
          InputLabelProps={{
            shrink: true
          }}
          width={300}
        />
        <div>
          <p style={{ float: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>Creado por:</span> Ramsés López
          </p>
          <p style={{ float: 'right' }}>
            <span style={{ fontWeight: 'bold' }}>Fecha:</span> 5 de septiembre
          </p>
        </div>
      </DialogContent>
      <DialogActions>
        <Button text='Cancelar' onClick={handleClose} variant='outlined' />
        <Button text='Aceptar' onClick={handleAdd} variant='contained' />
      </DialogActions>
    </Dialog>
  )
}

export default ObservationDetail
