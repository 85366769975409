import React, { lazy } from 'react'
import {
  URL_CLIENTS,
  URL_CLIENTS_DETAIL,
  URL_CLIENT_APPOINTMENT_DETAIL
} from '../../utils/constants'

const Clients = lazy(() => import('./Clients'))
const ClientDetail = lazy(() => import('./ClientDetail'))
const ClientAppointment = lazy(() => import('./appointment'))

const routeClient = [
  {
    path: URL_CLIENTS,
    element: <Clients />
  },
  {
    path: URL_CLIENTS_DETAIL,
    element: <ClientDetail />
  },
  {
    path: URL_CLIENT_APPOINTMENT_DETAIL,
    element: <ClientAppointment />
  }
]

export default routeClient
