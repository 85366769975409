import { useContext } from 'react'
import { SnackBarContext } from '../context/snackbarContext'

const useSnackBars = () => {
  const context = useContext(SnackBarContext)

  if (!context) {
    throw new Error('Context not exist.')
  }
  return context
}

export default useSnackBars
