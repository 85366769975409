import React, { lazy } from 'react'
import { URL_DMS, URL_DMS_DETAIL, URL_DMS_SETTING } from '../../utils/constants'
const Dms = lazy(() => import('./index'))
const Document = lazy(() => import('./pages/Document'))
const DmsSettings = lazy(() => import('./pages/dms-settings'))

const routeDms = [
  {
    path: URL_DMS,
    element: <Dms />
  },
  {
    path: URL_DMS_DETAIL,
    element: <Document />
  },
  {
    path: URL_DMS_SETTING,
    element: <DmsSettings />
  }
]

export default routeDms
