import useSWR from 'swr'
import { getContractorById } from '../services/contractorService'

const useContractorById = (id) => {
  const {
    data: contractorData,
    isLoading,
    error: contractorError
  } = useSWR(`CONTRACTOR-${id}`, () => getContractorById(id), {
    revalidateOnFocus: false
  })
  const contractor = contractorData ? contractorData.data : null

  return {
    isLoading,
    contractor,
    contractorError
  }
}

export default useContractorById
