import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Button from '../Button'

function ConfirmDialog({ title, description, handleClose, handleConfirm }) {
  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button text='Cancelar' onClick={handleClose} variant='outlined' />
        <Button text='Aceptar' onClick={handleConfirm} variant='contained' />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
