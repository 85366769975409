import React from 'react'
import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/core'
import { FaSpinner } from 'react-icons/fa'

import { Link } from 'react-router-dom'

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
})

const Spinner = styled(FaSpinner)({
  animation: `${spin} 1s linear infinite`
})
Spinner.defaultProps = {
  'aria-label': 'loading'
}

function FullPageSpinner() {
  return (
    <div
      style={{
        fontSize: '4em',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spinner />
    </div>
  )
}

function CellRenderLink({ route, id }) {
  return (
    <Link key={id} to={`${route}/${id}`} style={{ textDecoration: 'none' }}>
      {id}
    </Link>
  )
}

export { Spinner, FullPageSpinner, CellRenderLink }
