import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function TabPage({ contractor_id, menu }) {
  const location = useLocation()
  return (
    <div className='tab-option-container bottom-border'>
      {menu.map((x) => (
        <div
          key={x.id}
          className={`tab-item ${location.pathname === x.url ? 'tab-item-selected' : ''}`}
        >
          <Link to={x.url}>
            <span>{x.name}</span>
          </Link>
        </div>
      ))}
    </div>
  )
}
