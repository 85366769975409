import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Breadcrumb from '../../../components/Breadcrumb'
import { URL_TOOL } from '../../../utils/constants'
import Button from '../../../components/Button/index'

/** module component */
import ToolCard from './components/ToolCard/ToolCard'

import useSnackBars from '../../../hooks/snackbarHook'
import ConfirmDialog from '../../../components/ConfirmDialog'

/** services */
import { getClients } from '../../../services/clientService'
import { getAllContractor } from '../../../services/contractorService'
import { getUsers } from '../../../services/settingService'
import {
  addProduct,
  deleteProduct,
  getProductById,
  updateProduct
} from '../../../services/inventoryService'

const ToolDetail = (props) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { addAlert } = useSnackBars()
  const [loading, setLoading] = useState(false)
  const [productId, setProductId] = useState(parseInt(id) ?? 'nuevo')
  const [product, setProduct] = useState({
    name: '',
    clientId: null,
    contractorId: null,
    responsibleUserId: null,
    description: '',
    location: '',
    status: 'good'
  })
  const [verifyDelete, setVerifyDelete] = useState(false)
  const [companiesData, setCompaniesData] = useState([])
  const [contractorsData, setContractorsData] = useState([])
  const [usersData, setUsersData] = useState([])

  const [validateName, setValidateName] = useState({
    error: false,
    helperText: ''
  })

  const [valClient, setValClient] = useState({
    error: false,
    helperText: ''
  })

  const [valContractor, setValContractor] = useState({
    error: false,
    helperText: ''
  })

  const [valResponsible, setValResponsible] = useState({
    error: false,
    helperText: ''
  })

  useEffect(() => {
    const loadCompanies = async () => {
      const res = await getClients()

      if (res && res.status === 200) {
        setCompaniesData(res.data)
      }
    }

    const loadContractors = async () => {
      const res = await getAllContractor()

      if (res && res.status === 200) {
        setContractorsData(res.data.data)
      }
    }

    const loadUsers = async () => {
      const res = await getUsers()

      if (res && res.status === 200) {
        setUsersData(
          res.data.map((user) => {
            return { id: user.id, name: `${user.firstName} ${user.lastName}` }
          })
        )
      }
    }

    const loadProduct = async () => {
      const resp = await getProductById(productId)

      if (resp && resp.status === 200) {
        setProduct(resp.data)
      }
      setLoading(false)
    }

    if (parseInt(productId) && !loading) {
      loadProduct()
    }

    loadCompanies()
    loadContractors()
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddProduct = async () => {
    let isValid = true

    if (product.name === '') {
      setValidateName({
        error: true,
        helperText: 'Es requerido el nombre de la herramienta'
      })
      isValid = false
    }

    if (product.clientId === null) {
      setValClient({
        error: true,
        helperText: 'Es requerido seleccionar un cliente'
      })
      isValid = false
    }

    if (product.contractorId === null) {
      setValContractor({
        error: true,
        helperText: 'Es requerido seleccionar un Contratista o una División Industrial'
      })
      isValid = false
    }

    if (product.responsibleUserId === null) {
      setValResponsible({
        error: true,
        helperText: 'Es requerido seleccionar un responsable'
      })
      isValid = false
    }

    if (!isValid) return

    const prod = {
      name: product.name,
      code: '--',
      status: product.status,
      description: product.description,
      location: product.location,
      clientId: product.clientId,
      contractorId: product.contractorId,
      responsibleUserId: product.responsibleUserId
    }

    const resp = await addProduct(prod)

    if (resp && resp.status === 201) {
      addAlert(`Ha sido creada la herramienta`)
      setProductId(resp.data.data.id)
      navigate(`${URL_TOOL}/${resp.data.data.id}`)
      setProduct(resp.data.data)
    }
  }

  const handleDeleteProduct = async () => {
    const resp = await deleteProduct(productId)

    if (resp && resp.status === 204) {
      addAlert(`El producto ha sido eliminado`)
      navigate(URL_TOOL)
    } else {
      addAlert(`No se pudo eliminar el producto`)
    }

    setVerifyDelete(false)
  }

  const handleUpdateProduct = async () => {
    const resp = await updateProduct(product)

    if (resp && resp.status === 200) {
      addAlert(`El producto ha sido actualizado`)
    } else {
      addAlert(`No se pudo actualizar el producto`)
    }
  }

  return (
    <div>
      <div>
        <Breadcrumb
          elements={[{ name: 'Herramientas', url: URL_TOOL }, { current: product.code }]}
        />
      </div>
      <hr />
      <div style={{ paddingTop: '10px', paddingBottom: '15px' }}>
        {!parseInt(productId) ? (
          <Button text='Crear' variant='contained' onClick={handleAddProduct} />
        ) : (
          <>
            <Button text='Guardar' variant='contained' onClick={handleUpdateProduct} />
            &nbsp;
            <Button
              text='Eliminar'
              variant='outlined'
              onClick={() => setVerifyDelete(true)}
            />
          </>
        )}
        &nbsp;
        <Link to={URL_TOOL}>
          <Button text='Atrás' variant='outlined' />
        </Link>
      </div>
      <br />
      <ToolCard
        validateName={validateName}
        updateValidateName={setValidateName}
        valClient={valClient}
        updateValClient={setValClient}
        product={product}
        setProduct={setProduct}
        companies={companiesData}
        contractors={contractorsData}
        users={usersData}
        valContractor={valContractor}
        updateContractor={setValContractor}
        valResponsible={valResponsible}
        updateResponsible={setValResponsible}
      />
      {verifyDelete && (
        <ConfirmDialog
          title='Eliminar herramienta'
          description='¿Está seguro que desea eliminar la herramienta? Una vez elminada no podrá ser
        restaurado.'
          handleClose={() => setVerifyDelete(false)}
          handleConfirm={handleDeleteProduct}
        />
      )}
    </div>
  )
}

export default ToolDetail
