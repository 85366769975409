import { getApi } from './api'

const groupToolsByClient = (tools) => {
  return new Promise(async (resolve) => {
    const clients = []
    for (const tool of tools) {
      if (tool.client) {
        /** 1. add first client element */
        if (!clients.some((item) => item.id === tool.client.id)) {
          clients.push({
            ...tool.client,
            products: [tool]
          })
        } else {
          const found = clients.find((client) => client.id === tool.client.id)
          found.products.push(tool)
        }
      }
    }
    resolve(clients)
  })
}

export async function getTools() {
  try {
    const resp = await getApi().get('/product', {})

    if (!resp) {
      console.log('Error getting list of products')
      return
    }

    const clientsGrouped = await groupToolsByClient(resp.data.data)

    return { tools: resp.data.data, clients: clientsGrouped }
  } catch (e) {
    console.log('⚠ catch error', e)
  }
}

export async function getProductById(productId) {
  try {
    const response = await getApi().get(`/product/${productId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function addProduct(tool) {
  try {
    const response = await getApi().post('/product', tool)

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

/**
 * Delete product
 * @param {Product.id} productId
 */
export async function deleteProduct(productId) {
  try {
    const response = await getApi().delete(`/product/${productId}`, {})

    if (!response) {
      console.log('Error deleting section')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

/**
 * Delete product
 * @param {Product} product
 */
export async function updateProduct(product) {
  try {
    const response = await getApi().put(`/product/${product.id}`, {
      ...product
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/**
 * Delete product
 * @param {Observation} observation
 */
export async function addObservation(observation) {
  try {
    const response = await getApi().post('/observation', observation)

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getObservationsByProduct(productId) {
  try {
    const resp = await getApi().get(`/observation/product/${productId}`, {})

    if (!resp) {
      return
    }

    return resp.data
  } catch (e) {
    console.log(e.status)
  }
}

/**
 * Update Picture Tool
 */
export async function uploadToolPicture(productId, file) {
  try {
    const response = await getApi().post(`/product/image/${productId}`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * Update Technical Document
 */
export async function uploadProductTechnicalDocument(productId, file) {
  try {
    const response = await getApi().post(`/product/technical/${productId}`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * Update image gallery product
 */
export async function uploadProductGalleryImg(productId, file) {
  try {
    const response = await getApi().post(`/product/gallery/${productId}`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * get product by token
 * @param {String} token
 */
export async function getProductByToken(token) {
  try {
    const response = await getApi().get(`/product/qr/${token}`, {})

    return response
  } catch (e) {
    return null
  }
}
