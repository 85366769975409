import React from 'react'
import { translateValidStatus, getValidityClassColor } from '@utils/assessment'

export default function Validity(cellData) {
  return cellData.value !== undefined && cellData.value !== null ? (
    <div className={`status_empire ${getValidityClassColor(cellData.value)}`}>
      <div>{translateValidStatus(cellData.value)}</div>
    </div>
  ) : (
    '--'
  )
}
