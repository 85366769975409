import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import attendanceReducer from '../pages/Attendance/ducks/reducer'

// Enables the Redux extension's communication.
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const middlewares = [thunk, routeMiddleware]
const rootReducer = combineReducers({
  router: connectRouter(history),
  attendance: attendanceReducer,
  carnet: attendanceReducer
})

const store = createStore(
  connectRouter(history)(rootReducer),
  composeEnhancers(applyMiddleware(...middlewares))
)

export { store, history }
