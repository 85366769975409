import React from 'react'
import { translateProductStatus } from '../../../../utils/assessment'

export default function ProductStatus(cellData) {
  const getClassColor = (value) => {
    switch (value) {
      case 'good':
        return '_green'
      case 'regular':
        return '_purple'
      default:
        return '_red'
    }
  }

  return cellData.value !== undefined ? (
    <div
      style={{ fontSize: '13px' }}
      className={`status_empire ${getClassColor(cellData.value)}`}
    >
      <div>{translateProductStatus(cellData.value)}</div>
    </div>
  ) : (
    ''
  )
}
