import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'

import Loading from '../../../../../components/Loading'

import './technical-doc.css'

import { uploadProductTechnicalDocument } from '../../../../../services/inventoryService'

import csv from '../../../../../assets/img/csv.png'
import pdf from '../../../../../assets/img/pdf.png'
import xls from '../../../../../assets/img/xls.png'
import xlsx from '../../../../../assets/img/xlsx.png'
import doc from '../../../../../assets/img/doc.png'
import docx from '../../../../../assets/img/docx.png'
import txt from '../../../../../assets/img/txt.png'

const images = {
  csv,
  pdf,
  xls,
  xlsx,
  doc,
  docx,
  txt
}

const TechnicalDocument = ({ productId, files, product, setProduct }) => {
  const [selectedFile, setSelectedFile] = useState()
  const [isFilePicked, setIsFilePicked] = useState(false)

  const uploadDocuments = async () => {
    try {
      // Create an object of formData
      const formData = new FormData()

      // Update the formData object
      formData.append('File', selectedFile)

      const res = await uploadProductTechnicalDocument(productId, formData)

      if (res && res.status === 200) {
        setProduct({ ...product, technicalDoc: res.data ? res.data.data : [] })
      }
      setIsFilePicked(false)
    } catch (error) {
      console.log(
        '🚀 ~ file: TechnicalDocument.jsx ~ line 109 ~ uploadDocuments ~ error',
        error
      )

      alert(
        'Lo sentimos no pudimos cargar el archivo ponganse en contacto con un administrador'
      )
    }
  }

  useEffect(() => {
    isFilePicked && uploadDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilePicked])

  // On file select (from the pop up)
  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0])
    setIsFilePicked(true)
  }

  function getImageByKey(key) {
    return images[key]
  }

  return (
    <div>
      {isFilePicked ? (
        <Loading />
      ) : (
        <>
          <div>
            <input
              accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .pdf, .doc, .docx, .txt'
              id='icon-button-file'
              type='file'
              style={{ display: 'none' }}
              onChange={onFileChange}
            />
            <label htmlFor='icon-button-file'>
              <IconButton
                color='primary'
                aria-label='upload document'
                size='medium'
                component='span'
              >
                <PostAddOutlinedIcon style={{ fontSize: 35 }} />
              </IconButton>
            </label>
          </div>

          <ul
            style={{
              listStyle: 'none',
              padding: 0,
              display: 'flex'
            }}
          >
            {files &&
              files.map((doc, i) => (
                <a key={i} href={doc.url} style={{ color: 'black' }}>
                  <li
                    key={i}
                    style={{
                      backgroundColor: '#EBEBEB',
                      borderRadius: 5,
                      padding: 10,
                      margin: 5,
                      width: 265,
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <img
                      src={getImageByKey(doc.extension)}
                      style={{
                        flex: 1,
                        maxWidth: 60,
                        maxHeight: 60
                      }}
                      alt='logo-for-file'
                    />
                    <p
                      style={{
                        flex: 1
                      }}
                    >
                      {doc.name}
                    </p>
                  </li>
                </a>
              ))}
          </ul>
        </>
      )}
    </div>
  )
}

export default TechnicalDocument
