import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'

export default function ({ id, caption, value, ...props }) {
  return (
    <>
      {caption && (
        <InputLabel shrink id={`label-${id}`}>
          {caption}
        </InputLabel>
      )}

      <TextField
        id={id}
        variant='standard'
        style={{ margin: 0 }}
        fullWidth
        margin='normal'
        value={value || ''}
        {...props}
        InputLabelProps={{
          ...props.InputLabelProps,
          shrink: true
        }}
      />
    </>
  )
}
