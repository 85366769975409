import React, { lazy } from 'react'
import { URL_APPLICANT, URL_APPLICANT_CARD } from '../../utils/constants'

const Applicant = lazy(() => import('./index'))
const ApplicantCard = lazy(() => import('./ApplicantCard'))

const routeApplicant = [
  {
    path: URL_APPLICANT,
    element: <Applicant />
  },
  {
    path: URL_APPLICANT_CARD,
    element: <ApplicantCard />
  }
]

export default routeApplicant
