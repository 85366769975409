import React from 'react'
import { Provider } from 'react-redux'
import { store, history } from './redux/store'
import { ThemeProvider } from '@mui/styles'
import { SnackBarProvider } from './context/snackbarContext'
import './App.css'
import theme from './themes'
import InitRoutes from './router'

const App = () => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <SnackBarProvider>
        <InitRoutes history={history} />
      </SnackBarProvider>
    </Provider>
  </ThemeProvider>
)
export default App
