const INITIAL_STATE = {
  loadParticipants: false,
  selectedParticipantId: null,
  participant: {
    applicantId: null,
    firstName: '',
    firstSurname: '',
    secondSurname: '',
    identity: '',
    email: '',
    phone: '',
    avatarUrl: null
  },
  sheet: null,
  contractorOptions: [],
  companyOptions: []
};

const attendanceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_PARTICIPANT':
      return {
        ...state,
        participant: action.payload
      };
    case 'SET_SELECTED_PARTICIPANT_ID':
      return {
        ...state,
        selectedParticipantId: action.payload
      };
    case 'SET_PARTICIPANT_OPTIONS':
      return {
        ...state,
        companyOptions: action.payload.companyOptions,
        contractorOptions: action.payload.contractorOptions
      };
    default:
      return { ...state };
  }
};

export default attendanceReducer;
