import React from 'react'
import './page-title.css'
import { Link } from 'react-router-dom'

export default function PageTitle({ text, url = null, levelTwo }) {
  return (
    <div className='pt-container'>
      {url ? (
        <>
          <Link to={url}>{text}</Link> /
          <p>
            <span className='nav-link-detail'>{levelTwo}</span>
          </p>
        </>
      ) : (
        <h1>{text}</h1>
      )}
    </div>
  )
}
