import React from 'react'

import {
  useTable,
  useFlexLayout,
  usePagination,
  useFilters,
  useRowSelect
} from 'react-table'

import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Table,
  HeaderRow,
  Header,
  // Emoji,
  Row,
  Cell
  // Button,
  // Pagination
  // Input
  // Select
} from './Styles'

// Define a default UI for filtering
// function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
//   const count = preGlobalFilteredRows.length;
//   const [value, setValue] = React.useState(globalFilter);
//   const onChange = useAsyncDebounce((value) => {
//     setGlobalFilter(value || undefined);
//   }, 200);

//   return (
//     <span>
//       Search:{' '}
//       <input
//         value={value || ''}
//         onChange={(e) => {
//           setValue(e.target.value);
//           onChange(e.target.value);
//         }}
//         placeholder={`${count} records...`}
//         style={{
//           fontSize: '1.1rem',
//           border: '0'
//         }}
//       />
//     </span>
//   );
// }

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef()
  const resolvedRef = ref || defaultRef

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate
  }, [resolvedRef, indeterminate])

  return (
    <div style={{ display: 'flex' }}>
      <input type='checkbox' ref={resolvedRef} {...rest} />
    </div>
  )
})

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  // const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      // placeholder={`Buscar ${count} registros...`}
      style={{ width: '100%' }}
    />
  )
}

export default function MyTable({
  loading,
  enabledSelect,
  handleSelectList,
  showPager = true,
  showTotal = true,
  ...props
}) {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  )

  const {
    getTableProps,
    // getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    getRowProps,
    page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    onRowClick,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      ...props,
      defaultColumn
    },
    useFilters,
    usePagination,
    useFlexLayout,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'select-row',
          visible: enabledSelect !== undefined ? enabledSelect : false,
          Header: ({ getToggleAllRowsSelectedProps }, props) => (
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          )
        },
        ...columns
      ])
    }
  )

  const handleRowClick = (row) => {
    onRowClick && onRowClick(row.values)
  }

  React.useEffect(() => {
    if (enabledSelect) {
      handleSelectList(selectedFlatRows.map((d) => d.original))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds])

  const renderRow = (row, index, style = {}) => {
    if (!row) {
      return (
        <Row {...{ style, even: (index + 1) % 2 }}>
          <p>Loading more...</p>
        </Row>
      )
    }
    prepareRow(row)
    return (
      <Row
        {...row.getRowProps(
          getRowProps ? getRowProps(row) : { style, even: (index + 1) % 2 }
        )}
        onClick={onRowClick ? (_) => handleRowClick({ ...row }) : null}
      >
        {row.cells.map((cell) => {
          return (
            <Cell
              {...cell.getCellProps()}
              align={cell.column.align}
              backgroundColor={cell.column.backgroundColor}
              padding={cell.column.padding}
              visible={cell.column.visible !== undefined ? cell.column.visible : true}
            >
              {cell.render('Cell')}
            </Cell>
          )
        })}
      </Row>
    )
  }

  const pagination = pageOptions.length ? (
    <div className='pagination-component-container'>
      <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
        <NavigateBeforeIcon />
      </IconButton>
      <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
        <NavigateNextIcon />
      </IconButton>{' '}
      <span>
        Página{' '}
        <strong>
          {pageIndex + 1} de {pageOptions.length}
        </strong>{' '}
      </span>
      <span>| Ir a la página: </span>{' '}
      {/* <FormControl variant='standard' sx={{ m: 1 }}> */}
      <Input
        type='number'
        style={{ width: '50px' }}
        defaultValue={pageIndex + 1}
        value={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
      />
      {/* </FormControl> */} <span>Mostrar:</span>{' '}
      <FormControl variant='standard' sx={{ m: 0 }}>
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  ) : null

  const tableBody = page && page.length ? page.map((row, i) => renderRow(row, i)) : null

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table {...getTableProps()}>
        {headerGroups.map((headerGroup) => (
          <HeaderRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <Header
                {...column.getHeaderProps()}
                id={column.id}
                sorted={column.sorted}
                sortedDesc={column.sortedDesc}
                sortedIndex={column.sortedIndex}
                align={column.align}
                visible={column.visible !== undefined ? column.visible : true}
              >
                {column.component ? (
                  <div>{column.component}</div>
                ) : (
                  <span>{column.render('Header')}</span>
                )}
                <div>
                  {column.canFilter
                    ? column.allowFilter
                      ? column.render('Filter')
                      : null
                    : null}
                </div>
              </Header>
            ))}
          </HeaderRow>
        ))}
        {tableBody}
        <div className='container-between' style={{ marginTop: '12px' }}>
          {showTotal && (
            <>
              {loading ? (
                <div>
                  <strong>Cargando...</strong>
                </div>
              ) : (
                <div>
                  {enabledSelect ? (
                    <p>
                      <span>
                        <strong>{Object.keys(selectedRowIds).length}</strong> filas
                        seleccionadas {'/ '}
                        <strong>{rows.length}</strong> registros
                      </span>
                    </p>
                  ) : (
                    <p>
                      <strong>{rows.length}</strong> registros
                    </p>
                  )}
                </div>
              )}
            </>
          )}
          {rows.length > 10 && showPager && pagination}
        </div>
      </Table>
    </div>
  )
}
