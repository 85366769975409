import React, { lazy } from 'react'
import { URL_EXECUTIVE, URL_EXECUTIVE_DETAIL } from '../../utils/constants'

const Executive = lazy(() => import('./index'))
const ExecutiveDetail = lazy(() => import('./ExecutiveDetail'))

const routeExecutive = [
  {
    path: URL_EXECUTIVE,
    element: <Executive />
  },
  {
    path: URL_EXECUTIVE_DETAIL,
    element: <ExecutiveDetail />
  }
]

export default routeExecutive
