import React, { lazy } from 'react'

import {
  URL_TRAININGS,
  URL_TRAININGS_DETAIL,
  URL_TRAININGS_DIAGNOSTICS,
  URL_TRAININGS_RESULTS,
  URL_TRAININGS_GROUPS
} from '../../utils/constants'
const Trainings = lazy(() => import('./Trainings'))
const TrainingDetail = lazy(() => import('./TrainingDetail'))
const TrainingDiagnostic = lazy(() => import('./components/TrainingDiagnostic'))
const Results = lazy(() => import('../Results/Results'))
const MasterGroups = lazy(() => import('./pages/master-groups'))

const routesTraining = [
  {
    path: URL_TRAININGS,
    element: <Trainings />
  },
  {
    path: URL_TRAININGS_DETAIL,
    element: <TrainingDetail />
  },
  {
    path: URL_TRAININGS_DIAGNOSTICS,
    element: <TrainingDiagnostic />
  },
  {
    path: URL_TRAININGS_RESULTS,
    element: <Results />
  },
  {
    path: URL_TRAININGS_GROUPS,
    element: <MasterGroups />
  }
]

export default routesTraining
