import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'
import Header from '../../components/Header'
import Loading from '../../components/Loading'

const Main = class extends React.PureComponent {
  render() {
    return (
      <>
        <Header />
        <Suspense fallback={<Loading />}>
          <div className='main-container'>
            <Outlet />
          </div>
        </Suspense>
      </>
    )
  }
}

Main.propTypes = {
  router: PropTypes.array,
  match: PropTypes.object
}

export default Main
