import { getApi } from './api'

export async function getClients() {
  try {
    /** get clients */
    const response = await getApi().get('/clients', { data: {} })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getClientDetail(clientId) {
  try {
    /** get clients */
    const response = await getApi().get(`/clients/${clientId}`, { data: {} })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function addClient(client) {
  try {
    /** get clients */
    const response = await getApi().post('/clients', { client })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function updateClient(client) {
  try {
    /** update client */
    const response = await getApi().put(`/clients/${client.id}`, { client })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

/** delete clients */
export async function deleteClient(clientId) {
  try {
    const response = await getApi().delete(`/clients/${clientId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return { status: e.status }
  }
}

export async function getAssessmentsByDate(dateInit, dateEnd, clientId) {
  try {
    const response = await getApi().get(
      `/clients/assessment/${clientId}?dateInit=${dateInit.toISOString()}&dateEnd=${dateEnd.toISOString()}`,
      {}
    )

    return response
  } catch (error) {
    console.log(
      '🚀 ~ file: clientService.js ~ line 69 ~ getAssessmentsByDate ~ error',
      error
    )
    return { status: 400, message: 'No se encontraron los resultados correcto' }
  }
}

export async function getContractorsOfClient(companyId) {
  try {
    const response = await getApi().get(`/clients/${companyId}/contractors`, {})

    return response
  } catch (error) {
    return { status: 400, message: 'No se encontraron los resultados correcto' }
  }
}

export async function uploadClientImageLogo(clientId, file) {
  try {
    const response = await getApi().post(`/clients/${clientId}/image`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function uploadClientVideoLogo(clientId, file) {
  try {
    const response = await getApi().post(`/clients/${clientId}/video`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function requestAppointmentInfo(companyId) {
  try {
    /** get clients */
    const response = await getApi().get(`/clients/${companyId}/request-appointment`, {
      data: {}
    })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function checkAppointment(appointmentId) {
  try {
    /** get clients */
    const response = await getApi().get(`/clients/appointment/${appointmentId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function createAppointment(contactForm, appointment, classification, files) {
  try {
    const formData = new FormData()
    formData.append(
      'appointment',
      JSON.stringify({
        ...contactForm,
        date: appointment.date.toISOString(),
        place: appointment.place,
        companyId: appointment.companyId,
        userId: classification.userId,
        matrixId: classification.id
      })
    )

    for (let i = 0; i < files.length; i++) {
      formData.append('multiples', files[i].fileData)
    }

    const response = await getApi().post('/clients/appointment', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getCompanyAppointments(companyId) {
  try {
    /** get clients */
    const response = await getApi().get(`/clients/${companyId}/appointment`, {
      data: {}
    })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function confirmAppointment(appointmentId) {
  try {
    const response = await getApi().post(
      `/clients/appointment/${appointmentId}/confirm`,
      {}
    )

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getCompanyRequirements(companyId) {
  try {
    /** get clients */
    const response = await getApi().get(`/clients/${companyId}/requirement`, {
      data: {}
    })

    return response
  } catch (e) {
    console.log(e.status)
  }
}
