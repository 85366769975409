import { getApi } from './api'

export async function getAllContractor() {
  try {
    /** get clients */
    const response = await getApi().get('/contractor', { data: {} })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getAllContractorV2() {
  try {
    /** get clients */
    const response = await getApi().get('/contractors', { data: {} })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function addContractor(contractor) {
  try {
    const response = await getApi().post('/contractors', {
      contractor
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function putContractor(contractor) {
  try {
    const response = await getApi().put(`/contractors/${contractor.id}`, {
      contractor
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function delContractor(contractorId) {
  try {
    const response = await getApi().delete(`/contractors/${contractorId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function addClientContractor(clientId, contractorId) {
  try {
    const client = {
      contractorId: contractorId
    }
    const response = await getApi().post(`/clients/${clientId}/contractors`, {
      client
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function delClientContractor(clientId, contractorId) {
  try {
    const client = {
      contractorId: contractorId
    }
    console.log('delClientContractor -> client', clientId)

    const response = await getApi().delete(`/clients/${clientId}/contractors`, {
      data: {
        client: client
      }
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function getContractorOptionsByClient(clientId) {
  try {
    const response = await getApi().get(`/clients/${clientId}/contractorOptions`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function getContractorPersonal(contractorId) {
  try {
    const response = await getApi().get(`/contractor/${contractorId}/personnel`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function getContractorById(contractorId) {
  try {
    const response = await getApi().get(`/contractors/${contractorId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function uploadContractorImageLogo(contractorId, file) {
  try {
    const response = await getApi().post(`/contractor/${contractorId}/image`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function uploadContractorVideoLogo(contractorId, file) {
  try {
    const response = await getApi().post(`/contractor/${contractorId}/video`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function getCompaniesAssigned(contractorId) {
  try {
    const response = await getApi().get(`/contractor/${contractorId}/company`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}
