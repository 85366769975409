import React from 'react' // eslint-disable-line no-unused-vars
import {
  FaPlus,
  FaPrint,
  FaFlag,
  FaPaperPlane,
  FaTimesCircle,
  FaSave,
  FaAddressCard,
  FaTrash,
  FaChevronLeft,
  FaChevronDown,
  FaLock,
  FaCheck,
  FaEnvelope,
  FaDownload,
  FaSearch,
  FaPeopleArrows
} from 'react-icons/fa'
import { Spinner } from '../lib'

const iconStyle = { fontSize: 12, fontWeight: 'lighter' }

function IconByName({ name }) {
  switch (name) {
    case 'fa-plus':
      return <FaPlus style={iconStyle} />
    case 'fa-print':
      return <FaPrint />
    case 'fa-flag':
      return <FaFlag />
    case 'fa-paper-plane':
      return <FaPaperPlane />
    case 'fa-times':
      return <FaTimesCircle />
    case 'fa-save':
      return <FaSave />
    case 'fa-user-card':
      return <FaAddressCard />
    case 'fa-trash':
      return <FaTrash />
    case 'fa-left':
      return <FaChevronLeft />
    case 'fa-down':
      return <FaChevronDown />
    case 'fa-lock':
      return <FaLock />
    case 'fa-check':
      return <FaCheck />
    case 'fa-email':
      return <FaEnvelope />
    case 'fa-download':
      return <FaDownload />
    case 'fa-search':
      return <FaSearch />
    case 'people-arrow':
      return <FaPeopleArrows />
    default:
      return null
  }
}

function Icon({ name, color }) {
  return (
    <i
      style={{
        float: 'left',
        paddingTop: '2px',
        marginLeft: '-2px',
        marginRight: '7px',
        color: color
      }}
    >
      <IconByName name={name} />
    </i>
  )
}

export default function ButtonVektor({
  text,
  icon,
  onClick,
  variant,
  disabled,
  isLoading
}) {
  return (
    <button
      className={!variant ? 'btn' : `btn ${variant}`}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <div style={{ display: 'flex' }}>
          <Spinner />
          <span style={{ marginLeft: 6 }}>Procesando...</span>
        </div>
      ) : (
        <React.Fragment>
          {icon && (
            <Icon name={icon} color={variant === 'color-blue' ? 'white' : '#24292f'} />
          )}
          <span>{text}</span>
        </React.Fragment>
      )}
    </button>
  )
}
