import React, { useState } from 'react'
import { Outlet, Link, useParams, useNavigate } from 'react-router-dom'
import TabPage from '../../components/tab-page'
import Loading from '../../components/Loading'
import Button from '../../components/ButtonVektor'
import Dialog from '../../components/Dialog/dialog'
import Input from '../../components/Input'
/** hooks */
import useContractorById from '../../hooks/use-contractor-by-id'
import useSnackBars from '../../hooks/snackbarHook'
import { URL_UNAUTHORIZED } from '../../utils/constants'

export default function Layout() {
  const { contractorId } = useParams()
  const navigate = useNavigate()
  const { isLoading, contractor } = useContractorById(contractorId)
  const [showDialog, setShowDialog] = useState(false)
  const [invitation, setInvitation] = useState('')
  const { addAlert } = useSnackBars()
  const loggedUser = JSON.parse(localStorage.currentUser)

  if (
    loggedUser.role === 'contractor' &&
    loggedUser.contractorId !== parseInt(contractorId)
  ) {
    navigate(URL_UNAUTHORIZED)
  }

  const tabMenu = [
    { id: 1, name: 'General', url: `/contractors/${contractorId}` },
    // { id: 2, name: 'Evaluaciones', url: `/contractors/${contractorId}/assessment` },
    // { id: 3, name: 'Citas', url: `/contractors/${contractorId}/appointment` },
    // { id: 4, name: 'Archivos', url: `/contractors/${contractorId}/file` },
    { id: 5, name: 'Cumplimiento', url: `/contractors/${contractorId}/compliance` },
    { id: 6, name: 'Personal', url: `/contractors/${contractorId}/personnel` }
  ]

  const handleOpen = () => {
    if (contractor) {
      setInvitation(contractor.email)
    }
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const handleCopy = async () => {
    await navigator.clipboard.writeText(
      `https://app.vektorcr.com/public/new-user-contractor/${contractor.id}`
    )
    addAlert('Enlace copiado')
  }

  return (
    <div>
      <div className='nav-breadcrumb-container'>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='title--option-menu '>
            <div>
              {loggedUser.role !== 'contractor' && (
                <p>
                  <Link to='/contractors'>Directorio</Link> /{' '}
                  <span className='nav-link-detail'>Detalle del contratista</span>
                </p>
              )}

              <h1>{contractor && contractor.name}</h1>
            </div>
            <div className='option-container'>
              <Button text='Invitar usuario' variant='color-blue' onClick={handleOpen} />
              <Button text='Guardar' />
            </div>
          </div>
        )}
      </div>

      <TabPage menu={tabMenu} />

      {contractor && <Outlet context={{ contractor }} />}
      <Dialog
        open={showDialog}
        title='Invitar usuario contratista'
        content={
          <div>
            <p>Puede invitar al contratista mediante las siguientes opciones:</p>
            <div style={{ marginBottom: 14 }}>
              <Input
                id='contractor-email-phone'
                label='Correo o teléfono'
                value={invitation}
                onChange={(e) => setInvitation(e.currentTarget.value)}
              />
            </div>
            <div style={{ display: 'flex', gap: 6 }}>
              <Button text='Enviar por correo' onClick={() => setShowDialog(true)} />
              <Button
                text='Invitar por Whatsapp'
                onClick={() =>
                  window.open(
                    `https://wa.me/${invitation}/?text=${'Enlace para crear usuario en la plataforma de gestión de contratistas https://app.vektorcr.com/public/new-user-contractor/'}${
                      contractor.id
                    }`,
                    '_blank'
                  )
                }
              />
              <Button text='Copiar enlace' onClick={handleCopy} />
            </div>
          </div>
        }
        handleClose={handleClose}
        handleConfirm={handleClose}
      />
    </div>
  )
}
