import { getApi } from './api'

export async function getAttendanceSheets() {
  try {
    const response = await getApi().get('/attendance', {})

    return { status: 200, data: response.data.data }
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function getInstructorOptions() {
  try {
    const res = await getApi().get('/config/users', {})

    if (res && res.status === 200) {
      const parseOptions = await res.data.map((x) => ({
        id: x.id,
        name: x.firstName + ' ' + x.lastName
      }))
      return Promise.resolve({ status: 200, data: parseOptions })
    }
    return Promise.reject({ message: 'Cant load instructor options.' })
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function createSheet(sheet) {
  try {
    const res = await getApi().post('/attendance', sheet)

    if (!res) {
      console.log('Error')
      return Promise.reject({ message: 'Cant insert attendance sheet.' })
    }

    return Promise.resolve({ status: res.status, data: res.data.data })
  } catch (e) {
    return Promise.reject({ message: 'Error creating attendance sheet.' })
  }
}

export async function createAttendanceSheet(assessmentId, participantList) {
  try {
    const res = await getApi().post('/attendance/assessment', {
      assessmentId,
      participantList
    })

    if (!res) {
      console.log('Error')
      return Promise.reject({ message: 'Cant insert attendance sheet.' })
    }

    return Promise.resolve({ status: res.status, data: res.data })
  } catch (e) {
    return Promise.reject({ message: 'Error creating attendance sheet.' })
  }
}

export async function updateSheet(sheet) {
  try {
    const res = await getApi().put(`/attendance/${sheet.id}`, { ...sheet })

    if (!res) {
      console.log('Error')
      return Promise.reject({ message: 'Cant update attendance sheet.' })
    }

    return Promise.resolve({ status: res.status, data: res.data.data })
  } catch (e) {
    return Promise.reject({ message: 'Error updating attendance sheet.' })
  }
}

export async function createParticipant(participant, attendanceId) {
  try {
    const res = await getApi().post(`/attendance/${attendanceId}/createApplicant`, {
      ...participant
    })

    if (!res) {
      console.log('Error')
      return Promise.reject({ message: 'Cant insert attendance sheet.' })
    }

    return Promise.resolve({ status: res.status, data: res.data })
  } catch (e) {
    return Promise.reject({ message: 'Error creating attendance sheet.' })
  }
}

export async function getSheet(attendanceId) {
  try {
    const res = await getApi().get(`/attendance/${attendanceId}`, {})

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data })
    }
    return Promise.reject({ message: 'Cant load attendance sheet.' })
  } catch (e) {
    console.log(e)
  }
}

export async function getParticipantsSheet(attendanceId) {
  try {
    const res = await getApi().get(`/attendance/${attendanceId}/participants`, {})

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data.data })
    }
    return Promise.reject({ message: 'Cant load attendance sheet.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Cant load attendance sheet.' })
  }
}

export async function deleteParticipant(participantId) {
  try {
    const res = await getApi().delete(`/attendance/applicant/${participantId}`, {})

    if (res && res.status === 204) {
      return Promise.resolve({ status: 204, data: res.data })
    }
    return Promise.reject({ message: 'Cant delete participant.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Error deleting participant.' })
  }
}

export async function getParticipantById(participantId) {
  try {
    const res = await getApi().get(`/attendance/applicant/${participantId}`, {})

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data.data })
    }

    return Promise.reject({ message: 'Cant load participant.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Cant load attendance sheet.' })
  }
}

export async function updateParticipant(participant) {
  const res = await getApi().put(`/attendance/applicant/${participant.id}`, {
    ...participant
  })

  if (res && res.status === 200) {
    return Promise.resolve({ status: 200, data: res.data.data })
  }
  return Promise.reject({ message: 'Cant update participant.' })
}

export async function getPdfSheet(sheetId) {
  try {
    const res = await getApi().get(`attendance/${sheetId}/pdf`, {
      responseType: 'blob'
    })
    if (res) {
      const file = new File([res.data], 'file.pdf', {
        type: 'application/pdf'
      })

      return Promise.resolve(file)
    }
    return Promise.reject({ message: 'Cant print sheet.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Cant print attendance sheet.' })
  }
}

export async function updateSheetStatus(sheetId, status) {
  try {
    const res = await getApi().put(`/attendance/${sheetId}/status`, {
      status
    })

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data.data })
    }
    return Promise.reject({ message: 'Cant update attendance sheet.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Error updating attendance sheet.' })
  }
}

export async function uploadSheetFile(sheetId, file) {
  try {
    const response = await getApi().post(`/attendance/${sheetId}/file`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function uploadParticipantPhoto(participantId, file) {
  console.log(
    '🚀 ~ file: attendanceService.js ~ line 193 ~ uploadParticipantPhoto ~ file',
    file
  )
  console.log(
    '🚀 ~ file: attendanceService.js ~ line 193 ~ uploadParticipantPhoto ~ participantId',
    participantId
  )
  try {
    const response = await getApi().post(
      `/participant/${participantId}/identityPhoto`,
      file,
      {}
    )

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 *
 * @delete attendance sheet
 */
export async function deleteSheet(attendanceId) {
  try {
    const response = await getApi().delete(`/attendance/${attendanceId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/**
 * @post image gallery attendance sheet
 */
export async function uploadSheetImage(attendanceId, file) {
  try {
    const response = await getApi().post(`/attendance/gallery/${attendanceId}`, file, {})

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * @post send attendance sheet by email
 */
export async function sendAttendanceEmail(sheet, emails, message) {
  try {
    const response = await getApi().post(`/attendance/${sheet.id}/email`, {
      emails,
      message
    })

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * @post send multiple attendance sheet by email
 */
export async function sendMultiplesAttendanceEmail(sheetList, emails, message) {
  try {
    const response = await getApi().post('/attendance/emailSheets', {
      sheetList,
      emails,
      message
    })

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

/**
 * @post send multiple attendance sheet by email
 */
export async function deleteAWSPhoto(url, productId) {
  try {
    const response = await getApi().post('/attendance/deleteAWSPhoto', {
      sheetId: productId,
      url
    })

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}
