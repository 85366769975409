import styled, { css } from 'styled-components'

export const Table = styled.div`
  // display: block;
  // overflow: auto;
  // display: block;
  max-width: 100%;
  overflow-x: auto;
  display: table;
  width: 100%;
`

const RowBase = styled.div`
  border-bottom: solid 1px #ddd;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.43;
  align-items: center;
  min-height: 28px;
  display: table-row;

  ${(props) =>
    props.role === 'row' &&
    props.onClick &&
    css`
      cursor: pointer;
    `}

  :last-child {
    border-bottom: 0;
  }
`

export const Row = styled(RowBase)`
  :hover {
    background-color: rgb(238 238 238);
    transition: background-color 0.3s;
  }

  ${(props) =>
    props.even &&
    css`
      background-color: rgba(0, 0, 0, 0.024);
    `}
`

export const HeaderRow = styled(RowBase)`
  background: #fff;
  border-top: solid 1px rgba(0, 0, 0, 0.2);
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  // display: inline-flex;
`

export const Pagination = styled(RowBase)`
  color: black;
`

export const Cell = styled.div`
  display: table-cell;
  padding: 0.6rem;
  font-size: 14px;

  // word-break: break-all;

  ${(props) => {
    return (
      props.visible === false &&
      css`
        display: none;
      `
    )
  }};

  ${(props) => {
    return (
      props.align &&
      css`
        text-align: ${props.align};
      `
    )
  }};

  ${(props) => {
    return (
      props.backgroundColor &&
      css`
        background-color: ${props.backgroundColor};
      `
    )
  }};

  ${(props) => {
    return (
      props.padding &&
      css`
        padding: ${props.padding};
      `
    )
  }};

  ${(props) => {
    return (
      props.children.props?.column?.id === 'select-row' &&
      css`
        width: auto !important;
        flex: none !important;
      `
    )
  }}

  :last-child {
    border-right: 0;
  }
`

export const Header = styled(Cell)`
  font-weight: 600;
  line-height: 1.5rem;

  ${(props) => {
    return (
      props.visible === false &&
      css`
        display: none;
      `
    )
  }};

  ${(props) => {
    // const width = (props.sortedIndex + 1) * 5;
    return (
      props.align &&
      css`
        text-align: ${props.align};
      `
    )
  }};

  ${(props) => {
    return (
      props.id === 'select-row' &&
      css`
        width: auto !important;
        flex: none !important;
      `
    )
  }}
`

export const Button = styled.button`
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  color: black;

  :disabled {
    opacity: 0.3;
  }
`

export const Select = styled.select`
  appearance: none;
  background: white;
  border: 0;
  margin: 0;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.5rem 0.7rem;
  border: 0;
  cursor: pointer;
`

export const Input = styled.input`
  font-size: 1rem;
  padding: 0.5rem 0.7rem;
  background: white;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 100%;
  color: black;
`

export const Emoji = styled.span`
  font-size: 1rem;
  margin: 0 0.3rem;
  display: inline-block;
  transform: scale(1.4);
`
