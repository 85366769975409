import React, { useEffect, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getTools } from '../../../../services/inventoryService'

import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

/** local componentes */
import Table from '../../../../components/Table'
import Button from '../../../../components/Button'

import ProductCard from '../ProductCard/ProductCard'
import ClientCard from '../ClientCard/ClientCard'

import ProductStatus from '../../components/ProductStatus/ProductStatus'

import './toolCss.css'

const ToolData = () => {
  const [loading, setLoading] = useState(false)
  const [originalData, setOriginalData] = useState([])
  const [data, setData] = useState([])
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: true
  })

  const [clientList, setClientList] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const LinkCode = (cellData) => {
    return (
      <div>
        <Link
          to={`/tool/${cellData.row.original.id}`}
          style={{ textDecoration: 'none', textTransform: 'none !important' }}
        >
          {cellData.value}
        </Link>
      </div>
    )
  }

  const handleSelectedClient = (clientId) => {
    if (clientId !== null) {
      const filterList = originalData.filter((product) => product.clientId === clientId)
      setData(filterList)
      const client = clientList.find((x) => x.id === clientId)
      setSelectedClient(client)
    } else {
      setSelectedClient(null)
      setData(originalData)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Código',
        accessor: 'code',
        width: 30,
        align: 'center',
        visible: true,
        Cell: LinkCode,
        allowFilter: true
      },
      {
        Header: 'Nombre',
        accessor: 'name',
        allowFilter: true
      },
      {
        Header: 'Contratista',
        accessor: 'contractor.name',
        allowFilter: true
      },
      {
        Header: 'Responsable',
        accessor: (d) => `${d.responsible.firstName} ${d.responsible.lastName}`,
        allowFilter: true
      },
      {
        Header: 'Estado',
        accessor: 'status',
        align: 'center',
        width: 100,
        allowFilter: true,
        Cell: ProductStatus
      }
    ],
    []
  )

  useEffect(() => {
    const loadTools = async () => {
      const result = await getTools()

      if (result) {
        setClientList(result.clients)
        setOriginalData(result.tools)
        setData(result.tools)
      }

      setLoading(false)
    }

    if (!loading) {
      loadTools()
    }
  }, [loading])

  return (
    <div>
      {selectedClient && (
        <div style={{}}>
          <p className='selected-client'>{selectedClient.name}</p>
          <Button text='Quitar' onClick={() => handleSelectedClient(null)} />
        </div>
      )}

      {selectedClient && (
        <>
          <div style={{ width: '100%', textAlign: 'right' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={state.checkedA}
                  onChange={handleChange}
                  name='checkedA'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
              }
              label='Vista de tarjetas'
            />
          </div>
          <div>
            {!state.checkedA ? (
              <Table
                {...{
                  data: data,
                  columns,
                  getRowProps: (_) => ({
                    style: {
                      fontSize: '14px'
                    }
                  })
                }}
              />
            ) : (
              <div
                style={{
                  backgroundColor: '#f5f5f5',
                  padding: '24px',
                  borderRadius: '4px'
                }}
              >
                <ul className='flex-container wrap'>
                  {data &&
                    data.map((product, index) => {
                      return (
                        <li key={index} className='flex-item'>
                          <ProductCard key={index} {...product} />
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
      {!selectedClient && clientList && (
        <div
          style={{
            backgroundColor: '#f5f5f5',
            padding: '24px',
            borderRadius: '4px'
          }}
        >
          <ul className='flex-container wrap'>
            {clientList.map((client, index) => {
              return (
                <li key={index} className='flex-item'>
                  <ClientCard
                    key={index}
                    {...client}
                    products={client.products}
                    selectedClient={handleSelectedClient}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ToolData
