import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

export default function ({
  id,
  caption,
  value,
  setValue,
  options,
  error,
  helperText,
  hideClearButton,
  nullText,
  disabled,
  label
}) {
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const defaultProps = {
    options: options,
    disableClearable: hideClearButton || false,
    getOptionLabel: (option) => (option.name ? option.name : '')
  }

  return (
    <>
      {caption && (
        <InputLabel shrink id={`label-${id}`} sx={{ fontSize: '14px' }}>
          {caption}
        </InputLabel>
      )}
      <Autocomplete
        {...defaultProps}
        id={`select-${id}`}
        disabled={disabled}
        onChange={handleChange}
        defaultValue={null}
        value={options.find((x) => x.id === value) || null}
        renderOption={(props, option) => (
          <li {...props} key={option.id} style={{ fontSize: '14px' }}>
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            id={`select-text-${id}`}
            variant='standard'
            label={label}
            helperText={helperText}
            placeholder={nullText || null}
            error={error}
            {...params}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true
            }}
          />
        )}
      />
    </>
  )
}
