import styled from 'styled-components'

export const Container = styled.div`
  padding: 0 16px;
  z-index: 1000;
  margin-bottom: 10px;
  min-height: 44px;
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  color: white;
`

export const DivHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 40px;
  padding-left: 0;
`

export const PanelLeft = styled.div`
  // display: flex;
  // align-items: stretch;
  // flex: 1 1 auto;
  // padding-top: 0;
  // overflow: visible;
  background-color: white;
  position: fixed;
  box-shadow: rgb(0 0 0 / 22%) 0px 25.6px 57.6px 0px,
    rgb(0 0 0 / 18%) 0px 4.8px 14.4px 0px;
  z-index: 1000000;
  background-color: #fff;
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  box-sizing: border-box;
  outline: none;
  overflow-y: auto;
  visibility: visible;
  pointer-events: auto;
  top: 0;
  left: 0;
  right: auto;
  height: 100vh;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  -webkit-animation-duration: 0.18s;
  animation-duration: 0.18s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
`

export const LauncherHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fbfbfb;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 2px;
  height: 50px;
  padding: 0px 6px;
`

export const LaunchBody = styled.div`
  margin-top: 10px;
  display: inline;
`

export const LaunchOption = styled.div`
  color: black;
  border-radius: 2px;
  width: 100%;
  padding: 14px;
  letter-spacing: 0.2px;
  border: 1px solid white;
  display: flex;

  .option-text {
    font-weight: 400;
    margin-left: 5px;
    margin-top: 1.5px;
    margin-bottom: 0px;
  }

  &:hover {
    cursor: pointer;
    border-color: #f4f4f4 !important;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
    transition: border-color 0.167s linear, box-shadow 0.167s linear;
  }
`

export const LaunchTitle = styled.p`
  color: black;
    font-weight: 500;
    letter-spacing: 0.6px;
    margin-bottom: 0px;
}
`

export const PanelRight = styled.div`
  /* display: flex !important;
  align-items: stretch;
  flex: 0 0 auto;
  padding-top: 0;
  overflow: visible; */
`

export const Title = styled.h1`
  padding-right: 0;
  color: currentColor;
  display: flex;
  position: relative;
  margin: 0;
  font-size: 18px;
  vertical-align: top;
  white-space: nowrap;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
`
