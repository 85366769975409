import React, { useEffect, useState, useMemo } from 'react';

import { getObservationsByProduct } from '../../../../../../services/inventoryService';

/** local componentes */
import Table from '../../../../../../components/Table';

const ObservationData = ({ productId, loadData, setLoadData }) => {
  // const [loading, setLoading] = useState(loadData);
  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: 'Descripción',
        accessor: 'description',
        width: 400
      },
      {
        Header: 'Creado Por',
        accessor: (d) =>
          d.createdByUser !== null
            ? `${d.createdByUser.firstName} ${d.createdByUser.lastName}`
            : '--'
      },
      {
        Header: 'Fecha',
        align: 'center',
        width: 150,
        accessor: (d) =>
          new Date(d.createdAt).toLocaleString('es-CR', {
            hour12: true
          })
      }
    ],
    []
  );

  useEffect(() => {
    const loadProductObservations = async () => {
      const result = await getObservationsByProduct(productId);

      if (result) {
        setData(result.data);
      }

      setLoadData(false);
    };

    if (loadData) {
      loadProductObservations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadData]);

  return (
    <div>
      <Table
        {...{
          data: data,
          columns
        }}
      />
    </div>
  );
};

export default ObservationData;
