export const COORDINATOR = 'COORDINATOR'
export const IDLE = 'IDLE'
export const ACTIVE = 'ACTIVE'
export const CLOSED = 'CLOSED'
export const CANCEL = 'CANCEL'

// URL
export const URL_ROOT = '/'
export const URL_HOME = '/home'
export const URL_LOGIN = '/login'
export const URL_UNAUTHORIZED = '/unauthorized'
export const URL_CREATE = '/create'
export const URL_REGISTER = '/register'
export const URL_SURVEY = '/survey'
export const URL_SURVEYS = '/surveys'
export const URL_RESULTS = '/results'

export const URL_SETTINGS = '/settings'
export const URL_SETTINGS_USERS = '/settings/users'
export const URL_SETTINGS_USERS_DETAIL = '/settings/users/:id'
export const URL_SETTINGS_ASSESSMENTS = '/settings/assessments'
export const URL_SETTINGS_ASSESSMENTS_DETAIL = '/settings/assessments/:id'
export const URL_SETTINGS_COMPANY = '/settings/company'
export const URL_SETTINGS_LOGS = '/settings/logs'
export const URL_SETTINGS_QUESTIONS = '/settings/question'
export const URL_SETTINGS_QUESTION_DETAIL = '/settings/question/:id'
export const URL_SETTINGS_SECTIONS = '/settings/sections'
export const URL_SETTINGS_SECTION_DETAIL = '/settings/sections/:id'
export const URL_SETTINGS_MATRIX = '/settings/matrix'
export const URL_SETTINGS_MATRIX_COLUMN = '/settings/matrix/column'
export const URL_SETTINGS_MATRIX_ROW = '/settings/matrix/row'
export const URL_SETTINGS_MATRIX_CATEGORY = '/settings/matrix/category'
export const URL_SETTINGS_MATRIX_REQUIREMENT = '/settings/matrix/requirement'

export const URL_CLIENTS = '/clients'
export const URL_CLIENT_APPOINTMENT_DETAIL = '/clients/:id/appointment/:appointmentId'
export const URL_CLIENTS_DETAIL = '/clients/:id'
export const URL_CONTRACTORS = '/contractors'
export const URL_CONTRACTORS_DETAIL = '/contractors/:contractorId'

export const URL_APPLICANT_ASSESSMENT = '/applicantassessment/:token'
export const URL_APPLICANT_ASSESSMENT_CHILD = '/child-assessment/:token'
export const URL_APPLICANT_QR = '/applicantrecord/:qr'

export const URL_TRAININGS = '/trainings'
export const URL_TRAININGS_DETAIL = '/trainings/:id'
export const URL_TRAININGS_DIAGNOSTICS = '/trainings/:id/diagnostic'
export const URL_TRAININGS_GROUPS = '/trainings/:id/groups'
export const URL_TRAININGS_RESULTS = '/trainings/:id/result/:resultId'

export const URL_EXECUTIVE = '/executive'
export const URL_EXECUTIVE_DETAIL = '/executive/:id'

export const URL_APPLICANT = '/applicant'
export const URL_APPLICANT_CARD = '/applicant/:id'

export const URL_EXPIRED = '/expired'

/** Inventory Module */
export const URL_TOOL = '/tool'
export const URL_TOOL_DETAIL = '/tool/:id'
export const URL_PRODUCT_QR = '/product/:token'

/** DMS Module  */
export const URL_DMS = '/document'
export const URL_DMS_DETAIL = '/document/:id'
export const URL_DMS_SETTING = '/document/settings/all'

/** Identity Option */
export const URL_IDENTITY_LOGIN = '/cedula'

/** Attendance module */
export const URL_ATTENDANCE = '/attendance'
export const URL_ATTENDANCE_DETAIL = '/attendance/:id'

/** Identity Card Manager Module ICM */
export const URL_ICM = '/icm'
export const URL_ICM_DETAIL = '/icm/:id'
export const URL_PUBLIC_CARNET = '/v1/icm/:id'
export const URL_PUBLIC_CARNET_V1 = '/v1/carnet/:id'

export const URL_PUBLIC_PRIVACY_POLICY = '/privacy-policy'
export const URL_PUBLIC_ABOUT_US = '/about-us'
export const URL_PUBLIC_APPS_LINK = '/apps-link'

/* appointment */
export const URL_PUBLIC_CONFIRM_APPOINTMENT = '/public/company/appointment/:id/confirm'
export const URL_PUBLIC_REQUEST_APPOINTMENT = '/public/company/:id'
export const URL_PUBLIC_VIEW_APPOINTMENT = '/public/appointment/:id'

export const roles = [
  {
    id: 'admin',
    name: 'Administrador',
    value: 'Administrador'
  },
  {
    id: 'manager',
    name: 'Gestor',
    value: 'Gestor'
  },
  {
    id: 'user',
    name: 'Usuario',
    value: 'Usuario'
  },
  {
    id: 'security',
    name: 'Seguridad',
    value: 'Seguridad'
  },
  {
    id: 'contractor',
    name: 'Contratista',
    value: 'Contratista'
  }
]

export const assessType = [
  {
    id: 'single',
    name: 'Individual'
  },
  {
    id: 'shared',
    name: 'Grupal'
  }
]

export const courseType = [
  {
    id: 'critical_task_vektor',
    name: 'Tareas Críticas Vektor'
  },
  {
    id: 'critical_task_thactika',
    name: 'Tareas Críticas Thactika'
  },
  {
    id: 'induction',
    name: 'Inducción'
  }
]

export const questionType = [
  {
    id: 'single',
    name: 'Simple'
  },
  {
    id: 'trueOrFalse',
    name: 'V ó F'
  }
]

export const identityTypeOptions = [
  {
    id: 1,
    name: 'Cédula de Identidad',
    mask: [
      /[1-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      '-',
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ]
  },
  // {
  //   id: 2,
  //   name: 'Cédula Jurídica'
  // },
  {
    id: 3,
    name: 'DIMEX',
    mask: [
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ]
  },
  {
    id: 4,
    name: 'NITE',
    mask: [
      /[1-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/,
      /[0-9]/
    ]
  },
  {
    id: 5,
    name: 'Pasaporte',
    mask: false
  },
  {
    id: 6,
    name: 'ID Interno',
    mask: false
  },
  {
    id: 7,
    name: 'Identificación INE',
    mask: false
  }
]

export const defaultColumnsDocument = {
  description: true,
  department: false,
  category: true,
  owner: true,
  status: true,
  createdAt: false,
  versionDescription: false,
  versionCode: false,
  fileName: false
}

export const totalRecordOptions = [
  { id: 1, name: '500' },
  { id: 2, name: '2000' },
  { id: 3, name: '5000' },
  { id: 4, name: 'Todos los registros' }
]

export const statusInvitationOptions = [
  {
    id: 'in progress',
    name: 'En progreso'
  },
  {
    id: 'suspended',
    name: 'Reprobado'
  },
  {
    id: 'approved',
    name: 'Aprobado'
  },
  {
    id: 'to start',
    name: 'Por iniciar'
  },
  {
    id: 'disabled',
    name: 'Desactivado'
  }
]

export const options = {
  chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    toolbar: {
      show: true
    },
    zoom: {
      enabled: true
    }
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        }
      }
    }
  ],
  plotOptions: {
    bar: {
      horizontal: false
    }
  },
  xaxis: {
    type: 'text',
    categories: []
  },
  legend: {
    position: 'right',
    offsetY: 40
  },
  fill: {
    opacity: 1
  }
}

export const series = [
  {
    name: 'Por Iniciar',
    data: []
  },
  {
    name: 'En Progreso',
    data: []
  },
  {
    name: 'Inhabilitados',
    data: []
  },
  {
    name: 'Suspensos',
    data: []
  },
  {
    name: 'Aprobados',
    data: []
  }
]

export const verticalBarGraph = {
  chart: {
    height: 'auto',
    type: 'bar',
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      horizontal: true,
      dataLabels: {
        position: 'top'
      }
    }
  },
  dataLabels: {
    enabled: true,
    offsetX: 20,
    style: {
      fontSize: '12px',
      colors: ['#304758']
    }
  },
  legend: {
    show: false
  },
  yaxis: {
    labels: {
      style: {
        fontSize: '12px'
      }
    }
  }
}
