import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@mui/styles'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

/* utils */
import { getCookie } from '../../utils/general'

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  )
}

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8'
  },
  indicator: {
    backgroundColor: '#1890ff'
  }
})(Tabs)

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    color: '#000000',
    fontSize: '0.8rem',
    marginRight: theme.spacing(4),
    // fontFamily: ['Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    },
    '&$selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium
    },
    '&:focus': {
      color: '#40a9ff'
    }
  },
  selected: {}
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: '#2e1534'
  }
}))

export default function VektorTab({ tabList, cookieName }) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  React.useEffect(() => {
    if (cookieName) {
      const cookieValue = getCookie(cookieName)
      if (cookieValue) {
        setValue(parseInt(cookieValue))
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (event, newValue) => {
    if (cookieName) {
      document.cookie = `${cookieName}=${newValue}; path=/`
    }
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs value={value} onChange={handleChange} aria-label='ant example'>
          {tabList.map((item, index) => {
            return item.visible && <AntTab label={item.name} key={index} />
          })}
        </AntTabs>
        {tabList.map((item, index) => {
          return (
            item.visible && (
              <TabPanel
                key={index}
                index={index}
                value={value}
                style={item.style ? { backgroundColor: '#fcfcfc' } : {}}
              >
                {item.component && item.component}
              </TabPanel>
            )
          )
        })}
      </div>
    </div>
  )
}
