import React from 'react'
import Button from '@mui/material/Button'

export default function Btn({
  color,
  text,
  leftIcon,
  rightIcon,
  children,
  large,
  block,
  style,
  variant,
  visible,
  onClick,
  ...rest
}) {
  return (
    visible && (
      <Button
        variant={variant}
        style={style}
        color={color}
        onClick={onClick}
        disableElevation
        {...rest}
        sx={{ fontSize: '12px' }}
      >
        {text}
      </Button>
    )
  )
}

Btn.defaultProps = {
  visible: true,
  color: 'primary'
}
