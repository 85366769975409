import React from 'react';

const Inspection = () => {
  const category = [
    'Mantenimiento preventivo',
    'Mantenimiento correctivo',
    'Procedimientos de sanidad',
    'Pre-usos de seguridad',
    'Procedimientos operativos'
  ];

  return (
    <>
      {/* <p>No tiene inspecciones a la fecha</p> */}
      <ul className="flex-container wrap">
        {category.map((cat, i) => (
          <li
            className="flex-item file-card"
            key={i}
            style={{
              textAlign: 'center',
              height: '45px',
              color: 'white',
              fontSize: '16px',
              background: '#045799'
            }}
          >
            <p>{cat}</p>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Inspection;
