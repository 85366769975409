import React, { lazy } from 'react'
import { URL_ATTENDANCE, URL_ATTENDANCE_DETAIL } from '../../utils/constants'
const AttendanceSheets = lazy(() => import('./AttendanceSheets'))
const Sheet = lazy(() => import('./screens/sheet'))

const routeAttendance = [
  {
    path: URL_ATTENDANCE,
    element: <AttendanceSheets />
  },
  {
    path: URL_ATTENDANCE_DETAIL,
    element: <Sheet />
  }
]

export default routeAttendance
