import React, { useMemo } from 'react'
import Grid from '@mui/material/Grid'
import Moment from 'moment'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

/** local componentes */
import Table from '../../../../components/Table'
import ValidStatus from '../../../../components/validity'
import Button from '../../../../components/Button'

/** utils methods */
import { parseExpiredExcel } from '../../../../utils/general'

const ResultData = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'No.',
        accessor: 'id',
        width: 30,
        align: 'center',
        visible: true
      },
      {
        Header: 'Nombre y apellidos',
        accessor: (d) => `${d.Applicant.name} ${d.Applicant.lastName}`
      },
      {
        Header: 'Cédula',
        accessor: 'Applicant.identityCard'
      },
      {
        Header: 'Empresa',
        accessor: 'ClientAssessment.Client.name'
      },
      {
        Header: 'Contratista',
        accessor: 'Contractor.name'
      },
      {
        Header: 'Examen',
        accessor: 'ClientAssessment.assessment.name'
      },
      {
        Header: 'Estado',
        accessor: 'validityStatus',
        align: 'center',
        width: 100,
        Cell: ValidStatus
      },
      {
        Header: 'Vencimiento',
        accessor: (d) => Moment(d.validityDate).format('DD-MM-YYYY'),
        align: 'center'
      }
    ],
    []
  )

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = async (csvData, fileName) => {
    const parseData = await parseExpiredExcel(csvData)
    const ws = XLSX.utils.json_to_sheet(parseData)
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <Grid container spacing={1}>
      <br />
      <Grid item md={12} style={{ textAlign: 'right' }}>
        <Button
          variant='outlined'
          text='Exportar a excel'
          onClick={(e) => exportToCSV(data, 'examenes_vencidos')}
        />
      </Grid>

      <Grid item md={12}>
        <Table
          {...{
            data: data,
            columns
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ResultData
