import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  PanelLeft,
  LauncherHeader,
  LaunchTitle,
  LaunchBody,
  LaunchOption
} from '../styles'

import {
  URL_ATTENDANCE,
  URL_SETTINGS_USERS,
  URL_SETTINGS_ASSESSMENTS,
  URL_SETTINGS_QUESTIONS,
  URL_SETTINGS_SECTIONS,
  URL_ICM,
  URL_CONTRACTORS,
  URL_TOOL,
  URL_CLIENTS,
  URL_TRAININGS,
  URL_EXECUTIVE,
  URL_APPLICANT,
  URL_EXPIRED,
  URL_DMS
} from '../../../utils/constants'

import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import AppsIcon from '@mui/icons-material/Apps'
import AssignmentIcon from '@mui/icons-material/Assignment'
import GroupIcon from '@mui/icons-material/Group'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import SegmentIcon from '@mui/icons-material/Segment'
import BadgeIcon from '@mui/icons-material/Badge'
import HandymanIcon from '@mui/icons-material/Handyman'
import BuildIcon from '@mui/icons-material/Build'
import WorkIcon from '@mui/icons-material/Work'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import GroupsIcon from '@mui/icons-material/Groups'
import AlarmIcon from '@mui/icons-material/Alarm'
import GppGoodIcon from '@mui/icons-material/GppGood'

const menuOptions = [
  {
    id: 2,
    description: 'Usuarios',
    icon: <GroupIcon sx={{ color: '#064a8c' }} />,
    url: URL_SETTINGS_USERS
  },
  {
    id: 3,
    description: 'Exámenes',
    icon: <AssignmentIcon sx={{ color: '#4b53bc' }} />,
    url: URL_SETTINGS_ASSESSMENTS
  },
  {
    id: 4,
    description: 'Preguntas',
    icon: <QuestionAnswerIcon sx={{ color: '#d35230' }} />,
    url: URL_SETTINGS_QUESTIONS
  },
  {
    id: 5,
    description: 'Secciones',
    icon: <SegmentIcon sx={{ color: '#ff8f6b' }} />,
    url: URL_SETTINGS_SECTIONS
  },
  {
    id: 6,
    description: 'Carnets',
    icon: <BadgeIcon sx={{ color: '#0d69ae' }} />,
    url: URL_ICM
  },
  {
    id: 7,
    description: 'Contratistas',
    icon: <HandymanIcon sx={{ color: '#000000' }} />,
    url: URL_CONTRACTORS
  }
]

const AppLauncher = ({ closeLauncher, loggedUser }) => {
  const navigate = useNavigate()

  const handleOption = (option) => {
    closeLauncher(false)
    navigate(option)
  }

  return (
    <PanelLeft>
      <LauncherHeader>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <IconButton
            aria-label='more'
            id='long-button'
            aria-haspopup='true'
            onClick={() => closeLauncher(false)}
          >
            <AppsIcon sx={{ color: 'black' }} />
          </IconButton>
          <LaunchTitle>Módulos</LaunchTitle>
        </div>
        <IconButton
          aria-label='more'
          id='close-button'
          aria-haspopup='true'
          onClick={() => closeLauncher(false)}
        >
          <CloseIcon sx={{ color: 'black' }} />
        </IconButton>
      </LauncherHeader>
      <LaunchBody>
        {['admin', 'manager', 'user'].includes(loggedUser.role) && (
          <LaunchOption onClick={() => handleOption(URL_CLIENTS)}>
            <WorkIcon sx={{ color: 'black' }} /> <p className='option-text'>Clientes</p>
          </LaunchOption>
        )}
        {['admin', 'manager', 'user', 'security'].includes(loggedUser.role) && (
          <LaunchOption onClick={() => handleOption(URL_TRAININGS)}>
            <AssignmentIcon sx={{ color: 'black' }} />
            <p className='option-text'>Entrenamientos</p>
          </LaunchOption>
        )}
        {['admin', 'manager', 'user'].includes(loggedUser.role) && (
          <LaunchOption onClick={() => handleOption(URL_EXECUTIVE)}>
            <AutoAwesomeMotionIcon sx={{ color: 'black' }} />
            <p className='option-text'>Evaluaciones</p>
          </LaunchOption>
        )}

        {['admin', 'manager', 'user', 'security'].includes(loggedUser.role) && (
          <LaunchOption onClick={() => handleOption(URL_APPLICANT)}>
            <GroupsIcon sx={{ color: 'black' }} />
            <p className='option-text'>Participantes</p>
          </LaunchOption>
        )}
        <LaunchOption onClick={() => handleOption(URL_EXPIRED)}>
          <AlarmIcon sx={{ color: 'black' }} />
          <p className='option-text'>Próximas a vencer</p>
        </LaunchOption>
        {loggedUser.inventoryRole === 'manager' && (
          <LaunchOption onClick={() => handleOption(URL_TOOL)}>
            <BuildIcon /> <p className='option-text'>Herramientas y maquinarias</p>
          </LaunchOption>
        )}
        {['consultant', 'manager'].includes(loggedUser.dmsRole) && (
          <LaunchOption onClick={() => handleOption(URL_DMS)}>
            <GppGoodIcon sx={{ color: 'black' }} />
            <p className='option-text'>Gestión documental</p>
          </LaunchOption>
        )}

        {['administrator', 'manager', 'consultant'].includes(
          loggedUser.attendanceRole
        ) && (
          <LaunchOption onClick={() => handleOption(URL_ATTENDANCE)}>
            <CoPresentIcon sx={{ color: '#107c41' }} />{' '}
            <p className='option-text'>Asistencias</p>
          </LaunchOption>
        )}
        {menuOptions.map(
          (option) =>
            loggedUser.role === 'admin' && (
              <LaunchOption key={option.id} onClick={() => handleOption(option.url)}>
                {option.icon} <p className='option-text'>{option.description}</p>
              </LaunchOption>
            )
        )}
      </LaunchBody>
    </PanelLeft>
  )
}

export default AppLauncher
