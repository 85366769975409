import React from 'react'
import CheckboxMaterial from '@mui/material/Checkbox'

const Checkbox = ({ id, caption, style, ...props }) => {
  return (
    <div style={style}>
      <CheckboxMaterial
        id={`checkbox-${id}`}
        value='checkedA'
        {...props}
        // inputProps={{ 'aria-label': 'Checkbox A' }}
      />
      <label id={`label-checkbox-${id}`}>{caption}</label>
    </div>
  )
}

export default Checkbox
