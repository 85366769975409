import React from 'react'
import { useNavigate } from 'react-router-dom'
import AppsIcon from '@mui/icons-material/Apps'
import GroupsIcon from '@mui/icons-material/Groups'
import WorkIcon from '@mui/icons-material/Work'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import AlarmIcon from '@mui/icons-material/Alarm'
import BuildIcon from '@mui/icons-material/Build'
import GppGoodIcon from '@mui/icons-material/GppGood'
import LogoutIcon from '@mui/icons-material/Logout'
import EqualizerIcon from '@mui/icons-material/Equalizer'
import SettingsIcon from '@mui/icons-material/Settings'
import { clearAuth } from '../../pages/SignIn/helper'
import { Container, DivHeader } from './styles'
import {
  URL_HOME,
  URL_SETTINGS,
  URL_CLIENTS,
  URL_TRAININGS,
  URL_EXECUTIVE,
  URL_APPLICANT,
  URL_EXPIRED,
  URL_DMS
} from '../../utils/constants'

import AppLauncher from './components/AppLauncher'

function getMenuIcon(name) {
  switch (name) {
    case 'company':
      return <WorkIcon sx={{ color: 'white' }} />
    case 'training':
      return <AssignmentIcon sx={{ color: 'white' }} />
    case 'evaluations':
      return <AutoAwesomeMotionIcon sx={{ color: 'white' }} />
    case 'participants':
      return <GroupsIcon sx={{ color: 'white' }} />
    case 'overdue':
      return <AlarmIcon sx={{ color: 'white' }} />
    case 'tool':
      return <BuildIcon sx={{ color: 'white' }} />
    case 'document':
      return <GppGoodIcon sx={{ color: 'white' }} />
    case 'apps':
      return <AppsIcon sx={{ color: 'white' }} />
    case 'home':
      return <EqualizerIcon sx={{ color: 'white' }} />
    case 'logout':
      return <LogoutIcon sx={{ color: 'white' }} />
    case 'setting':
      return <SettingsIcon sx={{ color: 'white' }} />
    default:
      return null
  }
}

const MenuItem = ({ name, onClick, icon, disableMargin }) => (
  <div
    className={disableMargin ? 'header-menu-item-icon' : 'header-menu-item'}
    onClick={onClick}
  >
    <div className={name !== undefined ? 'icon-div' : 'icon-div-icon'}>
      {getMenuIcon(icon)}
    </div>
    {name !== undefined && <span className='menu-text'>{name}</span>}
  </div>
)

function Header() {
  const navigate = useNavigate()
  const loggedUser = JSON.parse(localStorage ? localStorage.currentUser : null)
  const [openLauncher, setOpenLauncher] = React.useState(false)

  const handleLogout = () => {
    clearAuth(true)
  }

  const handleNavigateMenu = (path) => {
    navigate(path)
  }

  if (!loggedUser) {
    handleLogout()
  }

  return (
    <Container>
      <DivHeader>
        <div className='header-sub-container'>
          {['admin', 'manager', 'user'].includes(loggedUser.role) && (
            <MenuItem icon='apps' disableMargin onClick={() => setOpenLauncher(true)} />
          )}
          {['admin', 'manager', 'user'].includes(loggedUser.role) && (
            <MenuItem name='VEKTOR' onClick={() => handleNavigateMenu(URL_HOME)} />
          )}
          <div className='modules-navbar'>
            {['admin', 'manager', 'user'].includes(loggedUser.role) && (
              <MenuItem
                icon='company'
                name='Clientes'
                onClick={() => handleNavigateMenu(URL_CLIENTS)}
              />
            )}
            {['admin', 'manager', 'user'].includes(loggedUser.role) && (
              <MenuItem
                icon='training'
                name='Entrenamientos'
                onClick={() => handleNavigateMenu(URL_TRAININGS)}
              />
            )}
            {['admin', 'manager', 'user'].includes(loggedUser.role) && (
              <MenuItem
                icon='evaluations'
                name='Evaluaciones'
                onClick={() => handleNavigateMenu(URL_EXECUTIVE)}
              />
            )}
            {['admin', 'manager', 'user', 'security'].includes(loggedUser.role) && (
              <MenuItem
                icon='participants'
                name='Participantes'
                onClick={() => handleNavigateMenu(URL_APPLICANT)}
              />
            )}
            <MenuItem
              icon='overdue'
              name='Próximas a Vencer'
              onClick={() => handleNavigateMenu(URL_EXPIRED)}
            />
            {['consultant', 'manager'].includes(loggedUser.dmsRole) && (
              <MenuItem
                icon='document'
                name='Gestión Documental'
                onClick={() => handleNavigateMenu(URL_DMS)}
              />
            )}
          </div>
        </div>
        <div className='header-sub-container'>
          <div className='user-name-logged'>
            <p>{`${loggedUser.firstName} ${loggedUser.lastName}`}</p>
          </div>
          {loggedUser.role === 'admin' && (
            <MenuItem icon='setting' onClick={() => handleNavigateMenu(URL_SETTINGS)} />
          )}
          <MenuItem icon='logout' onClick={handleLogout} />
        </div>
      </DivHeader>
      {openLauncher && (
        <AppLauncher closeLauncher={setOpenLauncher} loggedUser={loggedUser} />
      )}
    </Container>
  )
}

Header.propTypes = {}

export default Header
