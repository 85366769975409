import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import './styles/all.scss'
import App from './App'

window.process = {}
Sentry.init({
  dsn: 'https://246118efa7248d91eb8194f27154f0b0@o4508178462539776.ingest.us.sentry.io/4508178464243712',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, //  Capture 100% of the transactions 10% en producción, 100% en desarrollo
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.vektorcr\.com\/api/],

  // Session Replay
  replaysSessionSampleRate: process.env.NODE_ENV === 'production' ? 0.1 : 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const container = document.getElementById('root')
ReactDOM.render(<App />, container)
