import React, { useState, useEffect, useCallback } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
export const SnackBarContext = React.createContext()

export function SnackBarProvider({ children }) {
  const [alerts, setAlerts] = useState([])
  const [open, setOpen] = useState(false)

  const addAlert = (alert, type = 'info') =>
    setAlerts((alerts) => [{ message: alert, type }, ...alerts])

  const handleClose = useCallback((key) => {
    setAlerts((alerts) => alerts.filter((alert) => alert.message !== key))
  }, [])

  const value = { addAlert }

  useEffect(() => {
    if (alerts.length > 0) {
      setOpen(true)
    }
  }, [alerts])

  return (
    <SnackBarContext.Provider value={value}>
      {children}
      {alerts.map((alert, index) => (
        <Snackbar
          key={index}
          open={open}
          autoHideDuration={2000}
          onClose={() => handleClose(alert.message)}
        >
          <Alert
            severity={alert.type}
            onClose={() => handleClose(alert.message)}
            icon={false}
          >
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </SnackBarContext.Provider>
  )
}
