import React from 'react'
import { Navigate } from 'react-router-dom'
import { checkAuthState, getLoggedUser } from '../utils/auth'
import { URL_UNAUTHORIZED, URL_LOGIN } from '../utils/constants'

export default function PrivateRoute({ children, allowedRoles = [], moduleRoles = [] }) {
  try {
    const isLoggedIn = checkAuthState()
    const loggedUser = getLoggedUser()

    if (!isLoggedIn || !loggedUser) {
      return <Navigate to={URL_LOGIN} />
    }

    // Check for general roles
    const hasGeneralRole =
      allowedRoles.includes(loggedUser.role) || allowedRoles.includes('*')

    if (!hasGeneralRole) {
      return <Navigate to={URL_UNAUTHORIZED} />
    }

    if (moduleRoles.length > 0) {
      const hasModuleRole = moduleRoles.some((moduleRole) => {
        const userModuleRole = loggedUser[moduleRole.module]
        return moduleRole.roles.includes(userModuleRole)
      })

      if (!hasModuleRole) {
        return <Navigate to={URL_UNAUTHORIZED} />
      }
    }

    return children
  } catch (e) {
    console.error(e)
    return <Navigate to={URL_UNAUTHORIZED} />
  }
}
