import { getApi } from './api'

export async function getAssessmentsConfig() {
  try {
    const response = await getApi().get('/config/assessments', { data: {} })

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getAssessmentById(assessmentId) {
  try {
    const response = await getApi().get(`/config/assessments/${assessmentId}`, {
      data: {}
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** update assessment properties */
export async function updateAssessment(assessment) {
  try {
    const response = await getApi().put(`/config/assessments/${assessment.id}`, {
      assessment
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** add question */
export async function addQuestion(question) {
  try {
    const response = await getApi().post('/config/questions/', {
      question
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** update question */
export async function updateQuestion(question) {
  try {
    const response = await getApi().put(`/config/questions/${question.questionId}`, {
      question
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** add assessment detail */
export async function addAssessmentDetail(assessmentId, body) {
  try {
    const response = await getApi().post(
      `/config/assessments/${assessmentId}/detail`,
      body
    )

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** update assessment detail */
export async function updateAssessmentDetail(editValues) {
  try {
    const response = await getApi().put(
      `/config/assessments/detail/${editValues.detailId}`,
      editValues
    )

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** delete assessment detail */
export async function deleteAssessmentDetail(assessDetail) {
  try {
    /** add assessments */
    const response = await getApi().delete(
      `/config/assessments/${assessDetail.assessmentId}/detail/${assessDetail.detailId}`,
      {}
    )

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function addAssessmentsConfig(assessment) {
  try {
    /** add assessments */
    const response = await getApi().post('/config/assessments', { assessment })

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function addClientAssessment(assessment) {
  try {
    const response = await getApi().post('/assessments', { assessment })

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function delClientAssessment(clientAssessmentId) {
  try {
    /** add assessments */
    const response = await getApi().delete(`/assessments/${clientAssessmentId}`, {})

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function deleteAssessment(assessmentId) {
  try {
    /** add assessments */
    const response = await getApi().delete(`/config/assessments/${assessmentId}`, {})

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function getQuestions() {
  try {
    /** add assessments */
    const response = await getApi().get('config/questions', {})

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}

export async function loadAssessmentsByClient(clientId) {
  try {
    const response = await getApi().get(`/assessments/client/${clientId}`, {})

    return response
  } catch (error) {}
}

/** get results of client assessment */
export async function getAssessmentResults(
  clientAssessmentId,
  totalResults,
  yearFilter,
  statusFilter
) {
  try {
    const response = await getApi().get(
      `/assessments/${clientAssessmentId}?totalRecords=${totalResults}&yearFilter=${yearFilter}&statusFilter=${statusFilter}`,
      {}
    )

    return response
  } catch (error) {}
}

/** delete results of client assessment */
export async function deleteAssessmentResult(clientAssessmentId) {
  try {
    const response = await getApi().delete(
      `/assessments/result/${clientAssessmentId}`,
      {}
    )

    return response
  } catch (error) {}
}

export async function sendInvitations(
  clientAssessmentId,
  invitations,
  contractorSelId,
  group,
  isMaster,
  assessmentId
) {
  try {
    const response = await getApi().post(`/assessments/${clientAssessmentId}`, {
      applicantsEmails: invitations,
      contractorId: contractorSelId,
      group,
      isMaster,
      assessmentId
    })

    return response
  } catch (error) {}
}

/** create new assessment group */
export async function postGroupInvitations(
  clientAssessmentId,
  contractorId,
  description,
  invitations
) {
  try {
    const response = await getApi().post('/assessmentResult/groupInvitation', {
      clientAssessmentId,
      contractorId,
      description,
      invitations
    })

    return response
  } catch (e) {
    console.error(e)
  }
}

/** post whatsapp invitation */
export async function addWhatsappInvitation(
  clientAssessmentId,
  contractorId,
  phone,
  group,
  isMaster,
  assessmentId
) {
  try {
    const response = await getApi().post('/assessment/executive/invitation/whatsapp', {
      clientAssessmentId,
      contractorId,
      phone,
      group,
      isMaster,
      assessmentId
    })

    return response
  } catch (e) {
    console.error(e)
  }
}

/** get assessment by token */
export async function getApplicantAssessment(token) {
  try {
    const response = await getApi().get(`/results/${token}`, {})

    return response
  } catch (error) {}
}

/** add answer */
export async function addAnswer(answer) {
  try {
    const response = await getApi().post('/config/answers', {
      answer
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** update answer */
export async function updateAnswer(answer) {
  try {
    const response = await getApi().put(`/config/answers/${answer.id}`, {
      answer
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** remove answer */
export async function deleteAnswer(answerId) {
  try {
    const response = await getApi().delete(`/config/answers/${answerId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** get results by id */
export async function getResults(resultId) {
  try {
    const response = await getApi().get(`/assessments/result/${resultId}`, {})

    return response
  } catch (e) {
    console.log(e.status)
    return {}
  }
}

export async function updateClientAssessment(clientAssessmentId, clientAssessment) {
  try {
    const response = await getApi().put(`/assessments/${clientAssessmentId}`, {
      clientAssessment
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function getAssessmentPdfResults(resultId) {
  try {
    const res = await getApi().get(`assessments/result/${resultId}/pdf`, {
      responseType: 'blob'
    })

    const file = new File([res.data], 'file.pdf', {
      type: 'application/pdf'
    })

    return file
  } catch (e) {
    console.log(e.status)
    return null
  }
}

/** get results of client assessment */
export async function getAssessmentResultById(resultId) {
  try {
    const response = await getApi().get(`/assessment/executive/${resultId}`, {})

    return response
  } catch (error) {}
}

/** add executive result */
export async function addResult(client, contractor, applicant, assessmentResult) {
  try {
    const response = await getApi().post('/assessment/executive', {
      client,
      contractor,
      applicant,
      assessmentResult
    })

    return response
  } catch (e) {
    console.error(e)
    return e
  }
}

/**
 * Get all diagnostic evaluations for assessment id
 * @param {*} assessId
 * @returns
 */
export const getDiagnosticResults = async (assessId) => {
  try {
    const response = await getApi().get(`/assessments/${assessId}/diagnostic`, {})

    return response.data
  } catch (e) {
    console.log(e)
  }
}

/**
 * Send diagnostic invitations
 * @param {*} id
 * @param {*} email
 * @param {*} contractorId
 * @returns
 */
export const sendDiagnosticInvitation = async (id, email, contractorId) => {
  try {
    const response = await getApi().post(`/assessments/${id}/diagnostic`, {
      emailApplicant: email,
      contractorId
    })

    return response
  } catch (e) {
    console.log(e)
  }
}

export const createFinalAssess = async (diagnosticId) => {
  try {
    const response = await getApi().post('/assessment/diagnostic', {
      diagnosticId
    })

    return response
  } catch (e) {
    console.log(e)
  }
}

export const rebootAssesResult = async (clientAssessmentId) => {
  try {
    const resp = await getApi().delete(
      `/assessments/result/reboot/${clientAssessmentId}`,
      {}
    )

    return resp
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function createAssessmentByIdentity(
  clientAssessmentId,
  contractorId,
  identityList,
  isDiagnostic,
  isMaster,
  assessmentId
) {
  try {
    const response = await getApi().post(
      '/assessment/executive/invitation/identityList',
      {
        clientAssessmentId,
        contractorId,
        identityList,
        isDiagnostic: isDiagnostic || false,
        isMaster,
        assessmentId
      }
    )

    return response
  } catch (e) {
    console.error(e)
  }
}

export async function createAssessmentByInternalId(
  clientAssessmentId,
  contractorId,
  identityList,
  isDiagnostic,
  isMaster,
  assessmentId
) {
  try {
    const response = await getApi().post(
      '/assessment/executive/invitation/by-internal-id',
      {
        clientAssessmentId,
        contractorId,
        identityList,
        isDiagnostic: isDiagnostic || false,
        isMaster,
        assessmentId
      }
    )

    return response
  } catch (e) {
    console.error(e)
  }
}

export async function getAssessmentOptions(clientId) {
  try {
    const res = await getApi().get(`/assessments/client/${clientId}`, {})

    if (res && res.status === 200) {
      return Promise.resolve({
        status: 200,
        data: res.data.map((x) => ({
          id: x.id,
          name: x.assessment.name
        }))
      })
    }
    return
  } catch (e) {
    console.error(e)
  }
}

export const getAssessByRangeDate = async (assessmentId, startDate, endDate) => {
  try {
    const res = await getApi().get(
      `/assessments/result/${assessmentId}/search?startDate=${startDate}&endDate=${endDate}`,
      {}
    )

    if (!res) {
      console.log('Error')
      return Promise.reject({ message: 'Cant not search results.' })
    }

    return Promise.resolve({ status: res.status, data: res.data.data })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Error getting results.' })
  }
}

export async function createAssessmentGroup(assessmentId, name) {
  try {
    const res = await getApi().post(`/assessments/${assessmentId}/group`, {
      name
    })

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function loadGroups(assessmentId) {
  try {
    const res = await getApi().get(`/assessments/${assessmentId}/group`, {})

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function deleteGroup(groupId) {
  try {
    const res = await getApi().delete(`/assessments/group/${groupId}`, {})

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function addGroupAssessments(groupId, data) {
  try {
    const res = await getApi().post(`/assessments/group/${groupId}/assessments`, {
      assessments: data
    })

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function getGRoupById(groupId) {
  try {
    const res = await getApi().get(`/assessments/group/${groupId}`, {})

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function removeEvaluation(groupId, evaluationId) {
  try {
    const res = await getApi().delete(
      `/assessments/group/${groupId}/assessment/${evaluationId}`,
      {}
    )

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function getAnalyticResultGyGroup(groupId) {
  try {
    const res = await getApi().get(`/assessments/group/${groupId}/analytic`, {})

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function printAnalyticGroup(groupId, ranking) {
  try {
    const res = await getApi().get(`/assessments/group/${groupId}/pdf/${ranking}`, {
      responseType: 'blob'
    })
    if (res) {
      const file = new File([res.data], 'file.pdf', {
        type: 'application/pdf'
      })

      return Promise.resolve(file)
    }
    return Promise.reject({ message: 'Cant print sheet.' })
  } catch (e) {
    console.log(e)
    return Promise.reject({ message: 'Cant print attendance sheet.' })
  }
}

export async function moveAssessments(companyId, assessments, clientAssessmentId) {
  try {
    const res = await getApi().post(`/assessments/move/${clientAssessmentId}`, {
      assessments,
      companyId
    })

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function updAssessmentsInvitationDate(
  date,
  assessments,
  clientAssessmentId,
  typeDate
) {
  try {
    const link =
      typeDate === 'invitation'
        ? '/assessments/invitationDate'
        : '/assessments/completionDate'

    const res = await getApi().post(`${link}/${clientAssessmentId}`, {
      assessments,
      date
    })

    return res
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function removeAssessmentsResults(assessmentList, clientAssessmentId) {
  try {
    const response = await getApi().post(
      `/assessments/bulkdelete/${clientAssessmentId}`,
      {
        assessments: assessmentList
      }
    )

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function uploadAssessmentThumbnail(assessmentId, file) {
  try {
    const response = await getApi().post(
      `/config/assessments/${assessmentId}/image`,
      file,
      {}
    )

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function uploadAssessmentVideo(assessmentId, file) {
  try {
    const response = await getApi().post(
      `/config/assessments/${assessmentId}/video`,
      file,
      {}
    )

    return response
  } catch (e) {
    console.log(e.status)
    return e.message
  }
}

export async function getAssessmentCompositeById(assessmentId) {
  try {
    const response = await getApi().get(
      `/config/assessments/${assessmentId}/evaluation`,
      {
        data: {}
      }
    )

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}

export async function addChild(masterId, listAssessmentsID) {
  try {
    const response = await getApi().post(`/config/assessments/${masterId}/evaluation`, {
      assessments: listAssessmentsID
    })

    return response
  } catch (e) {
    console.log(e.status)
    return null
  }
}
export async function deleteAssessmentChild(masterId, assessmentId) {
  try {
    /** add assessments */
    const response = await getApi().delete(
      `/config/assessments/${masterId}/evaluation/${assessmentId}`,
      {}
    )

    return response
  } catch (e) {
    // console.log("e:");
    console.log(e.status)
  }
}
