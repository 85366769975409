export function getClassColor(value) {
  switch (value) {
    case 'in progress':
      return 'badge-info'
    case 'suspended':
      return '_red'
    case 'approved':
      return '_green'
    case 'to start':
      return 'badge-new'
    default:
      return '_disabled'
  }
}

export function translateStatus(value) {
  switch (value) {
    case 'in progress':
      return 'En progreso'
    case 'suspended':
      return 'Reprobado'
    case 'approved':
      return 'Aprobado'
    case 'to start':
      return 'Por iniciar'
    default:
      return 'Desactivado'
  }
}

export const getValidityClassColor = (value) => {
  switch (value) {
    case 'valid':
      return '_green'
    default:
      return 'not-valid'
  }
}

export function translateAppointmentStatus(value) {
  switch (value) {
    case 'pending':
      return 'Pendiente'
    case 'confirmed':
      return 'Confirmado'
    case 'cancelled':
      return 'Cancelado'
    case 'in_progress':
      return 'En progreso'
    case 'completed':
      return 'Completado'
    case 'rescheduled':
      return 'Reprogramado'
    case 'no_show':
      return 'No se presentó'
    case 'pending_confirmation':
      return 'No confirmado'
    case 'on_hold':
      return 'En espera'
    case 'rejected':
      return 'Rechazado'
    default:
      return 'Desconocido'
  }
}

export function translateValidStatus(value) {
  switch (value) {
    case 'expired':
      return 'Vencido'
    default:
      return 'Vigente'
  }
}

export function translateRequirement(value) {
  switch (value) {
    case 'meets':
      return 'Cumple'
    case 'fails':
      return 'No Cumple'
    case 'enabled':
      return 'Activado'
    case 'disabled':
      return 'Desactivado'
    case 'suspended':
      return 'Suspendido'
    default:
      return 'No aplica'
  }
}

export function translateProductStatus(value) {
  switch (value) {
    case 'good':
      return 'bueno'
    case 'regular':
      return 'regular'
    default:
      return 'malo'
  }
}
