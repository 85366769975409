import React, { lazy } from 'react'
import { URL_CONTRACTORS_DETAIL } from '../../utils/constants'

// import Layout from './contractor.layout'
// import { element } from 'prop-types'

import ContractorLayout from './contractor.layout'

const Directory = lazy(() => import('../../pages/Contractors/Contractors'))
const Contractor = lazy(() => import('./pages/contractor'))
const AssessmentList = lazy(() => import('./pages/assessment-list'))
const AppointmentList = lazy(() => import('./pages/appointment-list'))
const Files = lazy(() => import('./pages/files'))
const Compliance = lazy(() => import('./pages/compliance'))
const PersonalList = lazy(() => import('./pages/personal-list'))
const Requirement = lazy(() => import('./pages/requirement'))

const routeContractor = [
  {
    path: '/contractors',
    element: <Directory />
  },
  {
    path: URL_CONTRACTORS_DETAIL,
    element: <ContractorLayout />,
    children: [
      {
        path: '',
        element: <Contractor />
      },
      {
        path: 'assessment',
        element: <AssessmentList />
      },
      {
        path: 'appointment',
        element: <AppointmentList />
      },
      {
        path: 'file',
        element: <Files />
      },
      {
        path: 'compliance',
        element: <Compliance />
      },
      {
        path: 'personnel',
        element: <PersonalList />
      }
    ]
  },
  {
    path: '/contractors/requirement/:id',
    element: <Requirement />
  }
]

export default routeContractor
