import React from 'react';
import Select from 'react-select';

const ReactSelect = ({ id, label, options, selectedOption, setValue, ...props }) => {
  const handleChange = (selectedOption) => {
    setValue(selectedOption);
  };

  return (
    <>
      {label && (
        <label htmlFor={`select-react-${id}`} style={{ paddingLeft: '2px' }}>
          {label}:
        </label>
      )}
      <Select
        id={`select-react-${id}`}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        {...props}
      />
    </>
  );
};

export default ReactSelect;
