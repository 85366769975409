import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

const StatusGroup = ({ value, selectedStatus }) => {
  const handleChange = (event) => {
    selectedStatus(event.target.value)
  }

  return (
    <FormControl component='fieldset'>
      <RadioGroup
        aria-label='gender'
        name='gender1'
        value={value}
        onChange={handleChange}
        row
      >
        <FormControlLabel value='good' control={<Radio />} label='Bueno' />
        <FormControlLabel value='regular' control={<Radio />} label='Regular' />
        <FormControlLabel value='bad' control={<Radio />} label='Malo' />
      </RadioGroup>
    </FormControl>
  )
}

export default StatusGroup
