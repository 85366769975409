import React from 'react';
import DatePickerUI from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './customDatePicker.css';

export default function DatePicker({ id, label, date, setValue, dateFormat, ...props }) {
  return (
    <>
      <label htmlFor={`date-picker-${id}`} style={{ paddingLeft: '2px' }}>
        {label}:
      </label>
      <DatePickerUI
        id={`date-picker-${id}`}
        wrapperClassName="datePickerWrapper"
        className="datePicker"
        selected={date}
        onChange={(date) => setValue(date)}
        dateFormat={dateFormat}
        {...props}
      />
    </>
  );
}
