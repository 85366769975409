import React from 'react'
import Grid from '@mui/material/Grid'
import Upload from 'antd/lib/upload'
import message from 'antd/lib/message'

/** import system component */
import Tab from '../../../../../components/Tab'
import Input from '../../../../../components/Input'

/** local component */
import GeneralInformation from './GeneralInformation'
import TechnicalDocument from '../TechnicalDocument'
import ProductGallery from '../ProductGallery'
import Inspection from '../Inspection'
import Observation from '../Observation'
import './css/mainCard.css'

/** services */
import { uploadToolPicture } from '../../../../../services/inventoryService'

const ToolCard = ({
  validateName,
  updateValidateName,
  valClient,
  updateValClient,
  product,
  setProduct,
  companies,
  contractors,
  users,
  valContractor,
  updateContractor,
  valResponsible,
  updateResponsible
}) => {
  const [loadingUpload, setLoadingUpload] = React.useState(false)
  const [timeStamp, setTimeStamp] = React.useState(new Date().getTime())

  const handleUploadImage = async (value) => {
    const formData = new FormData()
    formData.append('file', value.file)

    const res = await uploadToolPicture(product.id, formData)
    if (res && res.status === 200) {
      // setProduct({ ...product, avatarUrl: res.data.data.url });
      handleImageChange({
        file: {
          status: 'done',
          imageUrl: res.data.data.url
        }
      })
      setLoadingUpload(false)
    }
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('Solo se pueden subir archivos JPG y PNG')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('La imagen debe ser menor de 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const handleImageChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoadingUpload(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      setProduct({ ...product, avatarUrl: info.file.imageUrl })
      setTimeStamp(new Date().getTime())
    }
  }

  const uploadButton = (
    <div>
      <div className='ant-upload-text'>Subir Foto</div>
    </div>
  )

  return (
    <Grid container spacing={0}>
      <Grid item md={12}>
        <Grid container spacing={0}>
          <Grid item md={8}>
            <Grid container spacing={0}>
              <Grid item md={12} style={{ marginBottom: '16px' }}>
                <Input
                  required
                  id='name-tool'
                  placeholder='Herramienta'
                  label='Nombre'
                  inputProps={{ style: { fontSize: 24 } }}
                  error={validateName.error}
                  helperText={validateName.helperText}
                  value={product.name}
                  onChange={(e) => {
                    updateValidateName({ error: false, helperText: '' })
                    setProduct({ ...product, name: e.target.value })
                  }}
                  margin='normal'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
              <Grid item md={3}>
                {product.id && (
                  <h4>
                    <span style={{ fontWeight: 'bold' }}>Código: </span> {product.code}
                  </h4>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={4}>
            {product.id && (
              <Grid container spacing={0}>
                <Grid item md={12} style={{ textAlign: 'right' }}>
                  <div
                    style={{
                      float: 'left',
                      width: '200px',
                      height: '135px',
                      textAlign: 'center',
                      marginLeft: '50px'
                    }}
                  >
                    {loadingUpload && <p>Cargando...</p>}
                    <Upload
                      name='avatar-tool'
                      listType='picture-card'
                      // className="avatar-uploader"
                      style={{ display: 'inline-table', height: '135px' }}
                      showUploadList={false}
                      customRequest={handleUploadImage}
                      beforeUpload={beforeUpload}
                      onChange={handleImageChange}
                    >
                      {product.avatarUrl ? (
                        <img
                          src={`${product.avatarUrl}?time=${timeStamp}`}
                          alt='avatar'
                          style={{ width: '100%', height: '100%' }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                  <div
                    style={{
                      float: 'right',
                      width: '135px',
                      textAlign: 'center'
                    }}
                  >
                    {product.qrData && (
                      <>
                        <img
                          key='qr-product'
                          src={product.qrData}
                          className='image'
                          alt='profile'
                          width='135px'
                        />
                        <a
                          href={product.qrData}
                          download={`product-${product.code}`}
                          style={{ color: 'black' }}
                        >
                          <button type='button'>Descargar QR</button>
                        </a>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <br />
      <br />
      <Grid item md={12}>
        <Tab
          tabList={[
            {
              name: 'Información General',
              value: 'general',
              component: (
                <GeneralInformation
                  product={product}
                  setProduct={setProduct}
                  companies={companies}
                  contractors={contractors}
                  users={users}
                  valClient={valClient}
                  updateValClient={updateValClient}
                  valContractor={valContractor}
                  updateContractor={updateContractor}
                  valResponsible={valResponsible}
                  updateResponsible={updateResponsible}
                />
              ),
              visible: true
            },
            {
              name: 'Documentación Técnica',
              value: 'technical',
              component: !isNaN(product.id) && (
                <TechnicalDocument
                  productId={product.id}
                  files={product.technicalDoc}
                  product={product}
                  setProduct={setProduct}
                />
              ),
              visible: !isNaN(product.id)
            },
            {
              name: 'Imágenes',
              value: 'pictures',
              component: !isNaN(product.id) && (
                <ProductGallery
                  productId={product.id}
                  photos={product.gallery}
                  product={product}
                  setProduct={setProduct}
                />
              ),
              visible: !isNaN(product.id)
            },
            {
              name: 'Observaciones',
              value: 'observations',
              component: !isNaN(product.id) && <Observation productId={product.id} />,
              visible: !isNaN(product.id)
            },
            {
              name: 'Inspecciones',
              value: 'inspections',
              component: !isNaN(product.id) && <Inspection productId={product.id} />,
              visible: !isNaN(product.id)
            }
            // {
            //   name: 'Movimientos',
            //   value: 'movements',
            //   component: !isNaN(product.id) && <Inspection productId={product.id} />,
            //   visible: !isNaN(product.id)
            // }
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default ToolCard
