import { getApi } from './api'

export async function getUsers() {
  try {
    const response = await getApi().get(`/config/users`, {})

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getSections() {
  try {
    const response = await getApi().get(`/config/section`, { data: {} })

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function createSection(section) {
  try {
    const response = await getApi().post(`/config/section`, section)

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function updateSection(section) {
  try {
    const response = await getApi().put(`/config/section/${section.id}`, section)

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

/**
 *
 * @param {section ID} sectionId
 * @returns
 */
export async function removeSection(sectionId) {
  try {
    const response = await getApi().delete(`/config/section/${sectionId}`)

    if (!response) {
      console.log('Error deleting section')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getQuestion(questionId) {
  try {
    const response = await getApi().get(`/config/questions/${questionId}`, {})

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function getQuestionResults(id) {
  try {
    const response = await getApi().get(`/config/questions/${id}/assessment-results`, {})

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

/**
 * Update question
 * @param {Question} question
 */
export async function updateQuestion(question) {
  try {
    const response = await getApi().put(`/config/questions/${question.id}`, question)

    if (!response) {
      console.log('Error')
    }

    return response
  } catch (e) {
    console.log(e.status)
  }
}

export async function create(assessments) {
  try {
    const res = await getApi().post(`/v1/icm/fromEvaluations`, { assessments })

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data })
    }
    return Promise.reject({ message: 'Cant load identityId.' })
  } catch (e) {
    console.log(e)
  }
}

export async function createICMCountPrint(applicants) {
  try {
    const res = await getApi().post(`v1/config/count-print-icm`, { applicants })

    if (res && res.status === 200) {
      return Promise.resolve({ status: 200, data: res.data })
    }
    return Promise.reject({ message: 'Cant load identityId.' })
  } catch (e) {
    console.log(e)
  }
}
